import { styledComponent } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const EarNoseThrothExamination = ({
  selectedOnExamination,
  setSelectedOnExamination,
  prescriptionItems,
  isDelBtn = true,
}) => {
  let { earNoseThroths } = selectedOnExamination || {};

  const deleteData = (itemName) => {
    switch (itemName) {
      case 'larynx':
        earNoseThroths[itemName] = {
          supra_glottic_rt: false,
          supra_glottic_lt: false,
          glottic_rt: false,
          glottic_lt: false,
          subglottic: false,
          transglottic: false,
          note: earNoseThroths?.larynx?.note,
        };
        break;
      case 'hypopharynx':
        earNoseThroths[itemName] = {
          pyriform_fossa: false,
          post_cricoid: false,
          posterior_wall: false,
          note: earNoseThroths?.hypopharynx?.note,
        };
        break;
      case 'tongue':
        earNoseThroths[itemName] = {
          lateral_border: false,
          dorsum: false,
          tip: false,
          ventral_surface: false,
          note: earNoseThroths?.tongue?.note,
        };
        break;
      case 'oral_cavity':
        earNoseThroths[itemName] = {
          lip_floor_of_mouth: false,
          buccal: false,
          retromollar_trigon: false,
          maxillary_alveolus_and_hard_palate: false,
          mandibular_alveolus: false,
          note: earNoseThroths?.oral_cavity?.note,
        };
        break;
      case 'oropharynx':
        earNoseThroths[itemName] = {
          base_of_tongue: false,
          lateral_wall: false,
          soft_palate: false,
          posterior_wall: false,
          note: earNoseThroths?.oropharynx?.note,
        };
        break;
      case 'sinonasal':
        earNoseThroths[itemName] = {
          nasal_cavity: false,
          maxillary_sinus: false,
          ethmoid_sinus: false,
          frontal_sinus: false,
          sphenoid_sinus: false,
          all: false,
          note: earNoseThroths?.sinonasal?.note,
        };
        break;
      case 'nasopharynx':
        earNoseThroths[itemName] = '';
        break;
      case 'thyroid':
        earNoseThroths[itemName] = {
          left: false,
          right: false,
          both: false,
          note: earNoseThroths?.thyroid?.note,
        };
        break;
      case 'salivary_gland':
        earNoseThroths[itemName] = {
          parotid_rt: false,
          parotid_lt: false,
          submandibular_rt: false,
          submandibular_lt: false,
          sublingual: false,
          minor_salivary_gland: false,
          note: earNoseThroths?.salivary_gland?.note,
        };
        break;
      case 'skin':
        earNoseThroths[itemName] = {
          left: false,
          right: false,
          both: false,
          note: earNoseThroths?.skin?.note,
        };
        break;
      case 'site':
        earNoseThroths[itemName] = {
          lt: false,
          rt: false,
          both: false,
          central: false,
          note: earNoseThroths?.site?.note,
        };
        break;
      case 'size':
        earNoseThroths[itemName] = {
          three_four: false,
          four_three: false,
          five_two: false,
          note: earNoseThroths?.size?.note,
        };
        break;
      case 'consistency':
        earNoseThroths[itemName] = {
          firm: false,
          hard: false,
          soft: false,
          note: earNoseThroths?.consistency?.note,
        };
        break;
      case 'structure':
        earNoseThroths[itemName] = {
          free: false,
          firm: false,
          mobile: false,
          immobile: false,
          note: earNoseThroths?.structure?.note,
        };
        break;
      case 'level':
        earNoseThroths[itemName] = {
          ia: false,
          ib: false,
          ii: false,
          iii: false,
          iv: false,
          va: false,
          vb: false,
          note: earNoseThroths?.level?.note,
        };
        break;
      default:
        break;
    }
    selectedOnExamination.earNoseThroths = earNoseThroths;
    setSelectedOnExamination({ ...selectedOnExamination });
  };

  const deleteEarNoseThrothsNote = (itemName, indexName) => {
    switch (itemName) {
      case 'lymphoma':
      case 'nasopharynx':
        earNoseThroths[itemName] = '';
        break;
      default:
        earNoseThroths[itemName][indexName] = '';
    }
    selectedOnExamination.earNoseThroths = earNoseThroths;
    setSelectedOnExamination({ ...selectedOnExamination });
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['on-examination']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['on-examination']?.subItemStyle || {},
  );

  return (
    <div className="breast-exam-view">
      <ul>
        {earNoseThroths?.larynx?.supra_glottic_rt && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Supra Glottic(Rt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.larynx?.supra_glottic_lt && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Supra Glottic(Lt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.larynx?.glottic_lt && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Glottic (Lt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.larynx?.glottic_rt && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Glottic (Rt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.larynx?.subglottic && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Subglottic</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.larynx?.transglottic && (
          <li>
            <span className="text-left pr-5">
              <Title>Larynx: </Title>
              <Value>Transglottic</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'larynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.larynx?.supra_glottic_rt ||
          earNoseThroths?.larynx?.supra_glottic_lt ||
          earNoseThroths?.larynx?.glottic_lt ||
          earNoseThroths?.larynx?.glottic_rt ||
          earNoseThroths?.larynx?.subglottic ||
          earNoseThroths?.larynx?.transglottic) &&
          earNoseThroths?.larynx?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.larynx?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'larynx'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.larynx?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Larynx: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.larynx?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'larynx'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.hypopharynx?.pyriform_fossa && (
          <li>
            <span className="text-left pr-5">
              <Title>Hypopharynx: </Title>
              <Value> Pyriform fossa</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'hypopharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.hypopharynx?.post_cricoid && (
          <li>
            <span className="text-left pr-5">
              <Title>Hypopharynx: </Title>
              <Value>Post cricoid</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'hypopharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.hypopharynx?.posterior_wall && (
          <li>
            <span className="text-left pr-5">
              <Title>Hypopharynx: </Title>
              <Value>Posterior wall</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'hypopharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.hypopharynx?.pyriform_fossa ||
          earNoseThroths?.hypopharynx?.post_cricoid ||
          earNoseThroths?.hypopharynx?.posterior_wall) &&
          earNoseThroths?.hypopharynx?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.hypopharynx?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'hypopharynx'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.hypopharynx?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Hypopharynx: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.hypopharynx?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'hypopharynx'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.tongue?.lateral_border && (
          <li>
            <span className="text-left pr-5">
              <Title>Tongue: </Title>
              <Value>Lateral border</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'tongue'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.tongue?.dorsum && (
          <li>
            <span className="text-left pr-5">
              <Title>Tongue: </Title>
              <Value>Dorsum</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'tongue'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.tongue?.tip && (
          <li>
            <span className="text-left pr-5">
              <Title>Tongue: </Title>
              <Value>Tip</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'tongue'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.tongue?.ventral_surface && (
          <li>
            <span className="text-left pr-5">
              <Title>Tongue: </Title>
              <Value>Ventral surface</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'tongue'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.tongue?.lateral_border ||
          earNoseThroths?.tongue?.dorsum ||
          earNoseThroths?.tongue?.tip ||
          earNoseThroths?.tongue?.ventral_surface) &&
          earNoseThroths?.tongue?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.tongue?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'tongue'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.tongue?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Tongue: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.tongue?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'tongue'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.oral_cavity?.lip_floor_of_mouth && (
          <li>
            <span className="text-left pr-5">
              <Title>Oral cavity: </Title>
              <Value>Lip floor of mouth: </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oral_cavity'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oral_cavity?.buccal && (
          <li>
            <span className="text-left pr-5">
              <Title>Oral cavity: </Title>
              <Value>Buccal</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oral_cavity'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oral_cavity?.retromollar_trigon && (
          <li>
            <span className="text-left pr-5">
              <Title>Oral cavity: </Title>
              <Value>Retromollar trigon</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oral_cavity'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oral_cavity?.maxillary_alveolus_and_hard_palate && (
          <li>
            <span className="text-left pr-5">
              <Title>Oral cavity: </Title>
              <Value>Maxillary alveolus and hard palate</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oral_cavity'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oral_cavity?.mandibular_alveolus && (
          <li>
            <span className="text-left pr-5">
              <Title>Oral cavity: </Title>
              <Value>Mandibular alveolus</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oral_cavity'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.oral_cavity?.lip_floor_of_mouth ||
          earNoseThroths?.oral_cavity?.buccal ||
          earNoseThroths?.oral_cavity?.retromollar_trigon ||
          earNoseThroths?.oral_cavity?.maxillary_alveolus_and_hard_palate ||
          earNoseThroths?.oral_cavity?.mandibular_alveolus) &&
          earNoseThroths?.oral_cavity?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.oral_cavity?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'oral_cavity'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.oral_cavity?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Oral cavity: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.oral_cavity?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'oral_cavity'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.oropharynx?.base_of_tongue && (
          <li>
            <span className="text-left pr-5">
              <Title>Oropharynx: </Title>
              <Value>Base of tongue</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oropharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oropharynx?.lateral_wall && (
          <li>
            <span className="text-left pr-5">
              <Title>Oropharynx: </Title>
              <Value>Lateral wall</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oropharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oropharynx?.soft_palate && (
          <li>
            <span className="text-left pr-5">
              <Title>Oropharynx: </Title>
              <Value>Soft palate</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oropharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.oropharynx?.posterior_wall && (
          <li>
            <span className="text-left pr-5">
              <Title>Oropharynx: </Title>
              <Value>Posterior wall</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'oropharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.oropharynx?.base_of_tongue ||
          earNoseThroths?.oropharynx?.lateral_wall ||
          earNoseThroths?.oropharynx?.soft_palate ||
          earNoseThroths?.oropharynx?.posterior_wall) &&
          earNoseThroths?.oropharynx?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.oropharynx?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'oropharynx'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.oropharynx?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Oropharynx: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.oropharynx?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'oropharynx'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}

        {earNoseThroths?.nasopharynx && (
          <li>
            <span className="text-left pr-5">
              <Title>Nasopharynx: </Title>
              <Value>{earNoseThroths?.nasopharynx}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'nasopharynx'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {earNoseThroths?.sinonasal?.nasal_cavity && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>Nasal cavity</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.sinonasal?.maxillary_sinus && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>Maxillary sinus</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.sinonasal?.ethmoid_sinus && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>Ethmoid sinus</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.sinonasal?.frontal_sinus && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>Frontal sinus</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.sinonasal?.sphenoid_sinus && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>Sphenoid sinus</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.sinonasal?.all && (
          <li>
            <span className="text-left pr-5">
              <Title>Sinonasal: </Title>
              <Value>All</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'sinonasal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.sinonasal?.nasal_cavity ||
          earNoseThroths?.sinonasal?.maxillary_sinus ||
          earNoseThroths?.sinonasal?.ethmoidal_sinus ||
          earNoseThroths?.sinonasal?.frontal_sinus ||
          earNoseThroths?.sinonasal?.sphenoid_sinus ||
          earNoseThroths?.sinonasal?.all) &&
          earNoseThroths?.sinonasal?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.sinonasal?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'sinonasal'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.sinonasal?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Sinonasal: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.sinonasal?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'sinonasal'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}

        {earNoseThroths?.thyroid?.right && (
          <li>
            <span className="text-left pr-5">
              <Title>Thyroid: </Title>
              <Value>Right Lobe</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'thyroid'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.thyroid?.left && (
          <li>
            <span className="text-left pr-5">
              <Title>Thyroid: </Title>
              <Value>Left Lobe</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'thyroid'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.thyroid?.both && (
          <li>
            <span className="text-left pr-5">
              <Title>Thyroid: </Title>
              <Value>Both lobes</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'thyroid'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.thyroid?.right ||
          earNoseThroths?.thyroid?.left ||
          earNoseThroths?.thyroid?.both) &&
          earNoseThroths?.thyroid?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.thyroid?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'thyroid'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.thyroid?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Thyroid: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.thyroid?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'thyroid'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.salivary_gland?.parotid_rt && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Parotid(Rt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.salivary_gland?.parotid_lt && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Parotid(Lt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.salivary_gland?.submandibular_rt && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Submandibular(Rt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.salivary_gland?.submandibular_lt && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Submandibular(Lt)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.salivary_gland?.sublingual && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Sublingual</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.salivary_gland?.minor_salivary_gland && (
          <li>
            <span className="text-left pr-5">
              <Title>Salivary gland: </Title>
              <Value>Minor Salivary Gland</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'salivary_gland'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.salivary_gland?.parotid_rt ||
          earNoseThroths?.salivary_gland?.parotid_lt ||
          earNoseThroths?.salivary_gland?.submandibular_rt ||
          earNoseThroths?.salivary_gland?.submandibular_lt ||
          earNoseThroths?.salivary_gland?.sublingual ||
          earNoseThroths?.salivary_gland?.minor_salivary_gland) &&
          earNoseThroths?.salivary_gland?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.salivary_gland?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'salivary_gland'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.salivary_gland?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Salivary gland: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.salivary_gland?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'salivary_gland'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.skin?.scalp && (
          <li>
            <span className="text-left pr-5">
              <Title>Skin: </Title>
              <Value>Scalp</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'skin'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.skin?.face && (
          <li>
            <span className="text-left pr-5">
              <Title>Skin: </Title>
              <Value>Face</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'skin'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.skin?.neck && (
          <li>
            <span className="text-left pr-5">
              <Title>Skin: </Title>
              <Value>Neck</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'skin'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.skin?.scalp ||
          earNoseThroths?.skin?.face ||
          earNoseThroths?.skin?.neck) &&
          earNoseThroths?.skin?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.skin?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'skin'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.skin?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Skin: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.skin?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'skin'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
        {earNoseThroths?.lymphoma && (
          <li>
            <span className="text-left pr-5">
              <Title>Lymphoma: </Title>
              <Value>{earNoseThroths?.lymphoma}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'lymphoma'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {earNoseThroths?.site?.rt && (
          <li>
            <span className="text-left pr-5">
              <Title>Site: </Title>
              <Value>RT</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'site'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.site?.lt && (
          <li>
            <span className="text-left pr-5">
              <Title>Site: </Title>
              <Value>LT</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'site'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.site?.both && (
          <li>
            <span className="text-left pr-5">
              <Title>Site: </Title>
              <Value>Both</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'site'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.site?.central && (
          <li>
            <span className="text-left pr-5">
              <Title>Site: </Title>
              <Value>Central</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'site'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.site?.rt ||
          earNoseThroths?.site?.lt ||
          earNoseThroths?.site?.both ||
          earNoseThroths?.site?.central) &&
          earNoseThroths?.site?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.site?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'site'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.site?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Site: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.site?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'site'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}

        {earNoseThroths?.size?.three_four && (
          <li>
            <span className="text-left pr-5">
              <Title>Size: </Title>
              <Value>3/4</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'size'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.size?.four_three && (
          <li>
            <span className="text-left pr-5">
              <Title>Size: </Title>
              <Value>4/3</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'size'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.size?.five_two && (
          <li>
            <span className="text-left pr-5">
              <Title>Size: </Title>
              <Value>5/2</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'size'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.size?.three_four ||
          earNoseThroths?.size?.four_three ||
          earNoseThroths?.size?.five_two) &&
          earNoseThroths?.size?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.size?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'size'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.size?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Size: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.size?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'size'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}


        {earNoseThroths?.consistency?.firm && (
          <li>
            <span className="text-left pr-5">
              <Title>Consistency: </Title>
              <Value>Firm</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'consistency'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.consistency?.hard && (
          <li>
            <span className="text-left pr-5">
              <Title>Consistency: </Title>
              <Value>Hard</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'consistency'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.consistency?.soft && (
          <li>
            <span className="text-left pr-5">
              <Title>Consistency: </Title>
              <Value>Soft</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'consistency'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.consistency?.firm ||
          earNoseThroths?.consistency?.hard ||
          earNoseThroths?.consistency?.soft) &&
          earNoseThroths?.consistency?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.consistency?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'consistency'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.consistency?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Consistency: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.consistency?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'consistency'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}

        {earNoseThroths?.structure?.free && (
          <li>
            <span className="text-left pr-5">
              <Title>Relation with underlying structure: </Title>
              <Value>Free</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'structure'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.structure?.fixed && (
          <li>
            <span className="text-left pr-5">
              <Title>Relation with underlying structure: </Title>
              <Value>Fixed</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'structure'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.structure?.mobile && (
          <li>
            <span className="text-left pr-5">
              <Title>Relation with underlying structure: </Title>
              <Value>Mobile</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'structure'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.structure?.immobile && (
          <li>
            <span className="text-left pr-5">
              <Title>Relation with underlying structure: </Title>
              <Value>Immobile</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'structure'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.structure?.free ||
          earNoseThroths?.structure?.fixed ||
          earNoseThroths?.structure?.mobile ||
          earNoseThroths?.structure?.immobile) &&
          earNoseThroths?.structure?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.structure?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'structure'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.structure?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Relation with underlying structure: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.structure?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'structure'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}

        {earNoseThroths?.level?.ia && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>I(A)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.ib && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>I(B)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.ii && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>II</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.iii && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>III</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.iv && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>IV</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.va && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>V(A)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {earNoseThroths?.level?.vb && (
          <li>
            <span className="text-left pr-5">
              <Title>Level: </Title>
              <Value>V(B)</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'level'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(earNoseThroths?.level?.ia ||
          earNoseThroths?.level?.ib ||
          earNoseThroths?.level?.ii ||
          earNoseThroths?.level?.iii ||
          earNoseThroths?.level?.iv ||
          earNoseThroths?.level?.va ||
          earNoseThroths?.level?.vb) &&
          earNoseThroths?.level?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value> - {earNoseThroths?.level?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteEarNoseThrothsNote}
              itemName={'level'}
              delId={'note'}
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          earNoseThroths?.level?.note && (
            <div>
              <span className="text-left">
                <div>
                  <Title>Level: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value> - {earNoseThroths?.level?.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={deleteEarNoseThrothsNote}
                itemName={'level'}
                delId={'note'}
                isDelBtn={isDelBtn}
              />
            </div>
          )
        )}
      </ul>
    </div>
  );
};

export default EarNoseThrothExamination;
