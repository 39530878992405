import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CommonButtons from './commonButtons';

const NeurologyExamination = ({
  patientInfo,
  setPatientInfo,
  selectedOnExamination,
  handleOnExaminationData,
}) => {
  let { nervousSystem } = selectedOnExamination;

  const [fieldName, setFieldName] = useState('');
  const [isBtn, setIsBtn] = useState(false);
  const [btnInputArray, setBtnInputArray] = useState([
    3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ]);

  let defaultData = {
    gcs: { gcs: '', note: '' },
    consciousness: { status: '', note: '' },
    orientation: { status: '', type: '', note: '' },
    delusion: { status: '', note: '' },
    hallucination: { status: '', type: '', note: '' },
    memory: { status: '', type: '', note: '' },
    mmse: { value: '', note: '' },
    speech: { status: '', type: '', note: '' },
    cranialNerves: { status: '', type: '', note: '' },
    wasting: { status: '', type: '', note: '' },
    power: { value: '', type: '', note: '' },
    jerks: { status: '', type: '', note: '' },

    plantorResponse: { status: '', type: '', note: '' },
    cerebellarFunction: { status: '', type: '', note: '' },
    sensorySystem: { status: '', type: '', note: '' },
    gaitFunction: { status: '', type: '', note: '' },

    neckRigidity: { status: '', note: '' },
    kernigSign: { status: '', note: '' },
    brudzinskiSign: { status: '', note: '' },

    jvp: { status: '', note: '' },
    pulse: { status: '', note: '' },
    murmur: { status: '', note: '' },

    ascitis: { status: '', note: '' },
    hepatomegaly: { status: '', note: '' },
    splenomegaly: { status: '', note: '' },

    rr: { status: '', note: '' },
    rhanchi: { status: '', note: '' },
    creps: { status: '', note: '' },
    breathSound: { status: '', note: '' },
    gain: {},
  };

  const powerTypes = [' Rt UL', ' Lt UL', ' Rt LL', ' Lt LL'];

  let selectedData = nervousSystem || defaultData;

  const handleChange = (field, value, colName, gcsVal) => {
    const updatedData = { ...selectedData };

    // Handle special case for GCS
    if (field === 'gcs') {
      updatedData.gcs.value = gcsVal;
      setIsBtn(false);
      handleOnExaminationData(updatedData, 'nervousSystem');
      return;
    }

    // Extract the base field name and property type
    const [baseField, propertyType = 'status'] = field.replace(/Status|Type|Note|Value/g, '').match(/([a-z]+)(Status|Type|Note|Value)?$/i) || [];
    const camelCaseField = baseField.charAt(0).toLowerCase() + baseField.slice(1);

    if (!updatedData[camelCaseField]) {
      updatedData[camelCaseField] = {}; // Ensure the object exists
    }

    if (camelCaseField === 'power' && field === 'value') {
      updatedData.power.value = value !== '' ? Number(value) : ''; // Convert to number
    } else if (camelCaseField === 'power' && field === 'powerType') {
      // Toggle checkboxes for power.type
      const currentTypes = updatedData.power?.type || [];
      const newTypes = currentTypes.includes(value)
        ? currentTypes.filter((t) => t !== value) // Remove if already selected
        : [...currentTypes, value]; // Add if not selected

      updatedData.power.type = newTypes;
    } else {
      const propertyMap = {
        Status: 'status',
        Type: 'type',
        Note: 'note',
        Value: 'value',
      };

      const propertyToUpdate = field.match(/(Status|Type|Note|Value)$/)?.[0];
      const property = propertyMap[propertyToUpdate] || 'status';
      updatedData[camelCaseField][property] = value;
    }

    setIsBtn(false);
    handleOnExaminationData(updatedData, 'nervousSystem');
    return updatedData;
  };

  return (
    <>
      <Row className="title">
        <Col>Higher Cerebral Function</Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          {/* Glasgow Coma Scale */}
          <Row className="mb-3 align-items-flex-start general-examination">
            <Col md={3} className="label-text">
              Glasgow Coma Scale (GCS):
            </Col>
            <Col md={5}>
              <Form.Control
                className="form-control-sm"
                type="number"
                placeholder="Enter GCS"
                value={nervousSystem?.gcs?.value || ''} // Updated to use correct path
                onClick={() => setIsBtn(isBtn ? false : true)}
                onChange={(e) =>
                  handleChange('gcs', null, null, e.target.value)
                }
              />
              {isBtn && (
                <CommonButtons
                  unitArray={btnInputArray}
                  clickAction={handleChange}
                  rowName={'gcs'}
                />
              )}
            </Col>
            <Col md={4}>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                className="form-control-sm"
                style={{ height: '33px', width: '100%' }}
                value={nervousSystem?.gcs?.note || ''} // Updated to use correct path
                onChange={(e) => handleChange('gcsNote', e.target.value)}
              />
            </Col>
          </Row>

          {/* Level of Consciousness */}
          <Row className="mb-3 align-items-flex-start">
            <Col md={3} className="label-text">
              Level of Consciousness:
            </Col>
            <Col md={5}>
              <Row className="align-items-flex-start">
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="conscious"
                    label="Conscious"
                    name="consciousnessLevel"
                    checked={
                      nervousSystem?.consciousness?.status === 'conscious'
                    }
                    onClick={() =>
                      handleChange(
                        'consciousnessStatus',
                        nervousSystem?.consciousness?.status === 'conscious'
                          ? ''
                          : 'conscious',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="confused"
                    label="Confused"
                    name="consciousnessLevel"
                    checked={
                      nervousSystem?.consciousness?.status === 'confused'
                    }
                    onClick={() =>
                      handleChange(
                        'consciousnessStatus',
                        nervousSystem?.consciousness?.status === 'confused'
                          ? ''
                          : 'confused',
                      )
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="stuporous"
                    label="Stuporous"
                    name="consciousnessLevel"
                    checked={
                      nervousSystem?.consciousness?.status === 'stuporous'
                    }
                    onClick={() =>
                      handleChange(
                        'consciousnessStatus',
                        nervousSystem?.consciousness?.status === 'stuporous'
                          ? ''
                          : 'stuporous',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="coma"
                    label="Coma"
                    name="consciousnessLevel"
                    checked={nervousSystem?.consciousness?.status === 'coma'}
                    onClick={() =>
                      handleChange(
                        'consciousnessStatus',
                        nervousSystem?.consciousness?.status === 'coma'
                          ? ''
                          : 'coma',
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                className="form-control-sm"
                style={{ height: '33px', width: '100%' }}
                value={nervousSystem?.consciousness?.note || ''}
                onChange={(e) =>
                  handleChange('consciousnessNote', e.target.value)
                }
              />
            </Col>
          </Row>

          {/* Orientation */}
          <Row className="mb-3 align-items-flex-start">
            <Col md={3} className="label-text">
              Orientation:
            </Col>
            <Col md={5}>
              <Row className="align-items-flex-start">
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    label="Intact"
                    name="orientationStatus"
                    id="orientation_intact"
                    checked={nervousSystem?.orientation?.status === 'intact'}
                    onClick={() =>
                      handleChange(
                        'orientationStatus',
                        nervousSystem?.orientation?.status === 'intact'
                          ? ''
                          : 'intact',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    label="Lost"
                    name="orientationStatus"
                    id="orientation_lost"
                    checked={nervousSystem?.orientation?.status === 'lost'}
                    onClick={() =>
                      handleChange(
                        'orientationStatus',
                        nervousSystem?.orientation?.status === 'lost'
                          ? ''
                          : 'lost',
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col
              md={4}
              className={`d-flex align-items-flex-start ${
                nervousSystem?.orientation?.status === 'lost' ? 'gap-2' : ''
              }`}
            >
              {nervousSystem?.orientation?.status === 'lost' && (
                <div className="me-2" style={{ flex: '1' }}>
                  <Form.Select
                    size="sm"
                    className="form-control-sm"
                    style={{ width: '90%' }}
                    value={nervousSystem?.orientation?.type || ''}
                    onChange={(e) =>
                      handleChange('orientationType', e.target.value)
                    }
                  >
                    <option value="">Select...</option>
                    <option value="time">Time</option>
                    <option value="place">Place</option>
                    <option value="person">Person</option>
                  </Form.Select>
                </div>
              )}
              <div
                style={{
                  flex:
                    nervousSystem?.orientation?.status === 'lost' ? '1' : '1',
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Add note"
                  className="form-control-sm"
                  style={{ height: '33px' }}
                  value={nervousSystem?.orientation?.note || ''}
                  onChange={(e) =>
                    handleChange('orientationNote', e.target.value)
                  }
                />
              </div>
            </Col>
          </Row>

          {/* Delusion/Illusion */}
          <Row className="mb-3 align-items-center">
            <Col md={3} className="label-text">
              Delusion/Illusion:
            </Col>
            <Col md={5}>
              <Row>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="present"
                    name="delusionLevel"
                    label="Present"
                    checked={nervousSystem?.delusion?.status === 'present'}
                    onClick={() =>
                      handleChange(
                        'delusionStatus',
                        nervousSystem?.delusion?.status === 'present'
                          ? ''
                          : 'present',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="absent"
                    name="delusionLevel"
                    label="Absent"
                    checked={nervousSystem?.delusion?.status === 'absent'}
                    onClick={() =>
                      handleChange(
                        'delusionStatus',
                        nervousSystem?.delusion?.status === 'absent'
                          ? ''
                          : 'absent',
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                className="form-control-sm"
                style={{ height: '33px', width: '100%' }}
                value={nervousSystem?.delusion?.note || ''}
                onChange={(e) => handleChange('delusionNote', e.target.value)}
              />
            </Col>
          </Row>

          {/* Hallucination */}
          <Row className="mb-3 align-items-center">
            <Col md={3} className="label-text">
              Hallucination:
            </Col>
            <Col md={5}>
              <Row className="align-items-flex-start">
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="hallucination_present"
                    label="Present"
                    name="hallucinationStatus"
                    checked={nervousSystem?.hallucination?.status === 'present'}
                    onClick={() =>
                      handleChange(
                        'hallucinationStatus',
                        nervousSystem?.hallucination?.status === 'present'
                          ? ''
                          : 'present',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    id="hallucination_absent"
                    label="Absent"
                    name="hallucinationStatus"
                    checked={nervousSystem?.hallucination?.status === 'absent'}
                    onClick={() =>
                      handleChange(
                        'hallucinationStatus',
                        nervousSystem?.hallucination?.status === 'absent'
                          ? ''
                          : 'absent',
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col
              md={4}
              className={`d-flex align-items-center ${
                nervousSystem?.hallucination?.status === 'present'
                  ? 'gap-2'
                  : ''
              }`}
            >
              {nervousSystem?.hallucination?.status === 'present' && (
                <div className="me-2" style={{ flex: '1' }}>
                  <Form.Select
                    size="sm"
                    className="form-control-sm"
                    value={nervousSystem?.hallucination?.type || ''}
                    onChange={(e) =>
                      handleChange('hallucinationType', e.target.value)
                    }
                  >
                    <option value="">Select...</option>
                    <option value="auditory">Auditory</option>
                    <option value="visual">Visual</option>
                    <option value="tactile">Tactile</option>
                    <option value="gustatory">Gustatory</option>
                  </Form.Select>
                </div>
              )}
              <div
                style={{
                  flex:
                    nervousSystem?.hallucination?.status === 'present'
                      ? '1'
                      : '1',
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Add note"
                  className="form-control-sm"
                  style={{ height: '33px' }}
                  value={nervousSystem?.hallucination?.note || ''}
                  onChange={(e) =>
                    handleChange('hallucinationNote', e.target.value)
                  }
                />
              </div>
            </Col>
          </Row>

          {/* Memory */}
          <Row className="mb-3 align-items-flex-start">
            <Col md={3} className="label-text">
              Memory:
            </Col>
            <Col md={5}>
              <Row className="align-items-flex-start">
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    label="Intact"
                    name="memory"
                    id="memory_intact"
                    checked={nervousSystem?.memory?.status === 'intact'}
                    onClick={() =>
                      handleChange(
                        'memoryStatus',
                        nervousSystem?.memory?.status === 'intact'
                          ? ''
                          : 'intact',
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Check
                    type="radio"
                    label="Lost"
                    name="memory"
                    id="memory_lost"
                    checked={nervousSystem?.memory?.status === 'lost'}
                    onClick={() =>
                      handleChange(
                        'memoryStatus',
                        nervousSystem?.memory?.status === 'lost' ? '' : 'lost',
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col
              md={4}
              className={`d-flex align-items-flex-start ${
                nervousSystem?.memory?.status === 'lost' ? 'gap-2' : ''
              }`}
            >
              {nervousSystem?.memory?.status === 'lost' && (
                <div className="me-2" style={{ flex: '1' }}>
                  <Form.Select
                    size="sm"
                    className="form-control-sm"
                    value={nervousSystem?.memory?.type || ''}
                    onChange={(e) => handleChange('memoryType', e.target.value)}
                  >
                    <option value="">Select...</option>
                    <option value="immediate">Immediate</option>
                    <option value="short_term">Short Term</option>
                    <option value="long_term">Long Term</option>
                  </Form.Select>
                </div>
              )}
              <div
                style={{
                  flex: nervousSystem?.memory?.status === 'lost' ? '1' : '1',
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Add note"
                  className="form-control-sm"
                  style={{ height: '33px' }}
                  value={nervousSystem?.memory?.note || ''}
                  onChange={(e) => handleChange('memoryNote', e.target.value)}
                />
              </div>
            </Col>
          </Row>

          {/* MMSE */}
          <Row className="mb-3 align-items-center">
            <Col md={3} className="label-text">
              MMSE:
            </Col>
            <Col md={5}>
              <Form.Control
                size="sm"
                type="number"
                placeholder="Enter MMSE"
                value={nervousSystem?.mmse?.value || ''}
                onChange={(e) => handleChange('mmseValue', e.target.value)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                className="form-control-sm"
                style={{ height: '33px', width: '100%' }}
                value={nervousSystem?.mmse?.note || ''}
                onChange={(e) => handleChange('mmseNote', e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="title mt-4">
        <Col>Speech</Col>
      </Row>
      <hr />
      {/* Speech */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Speech:
        </Col>

        {/* Radio Buttons */}
        <Col md={5}>
          <Row className="mb-2">
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Normal"
                name="speech"
                id="speech_normal"
                checked={nervousSystem?.speech?.status === 'normal'}
                onClick={() => handleChange('speechStatus', 'normal')}
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Dysphonia"
                name="speech"
                id="speech_dysphonia"
                checked={nervousSystem?.speech?.status === 'dysphonia'}
                onClick={() => handleChange('speechStatus', 'dysphonia')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Dysarthria"
                name="speech"
                id="speech_dysarthria"
                checked={nervousSystem?.speech?.status === 'dysarthria'}
                onClick={() => handleChange('speechStatus', 'dysarthria')}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Dysphasia/Aphasia"
                name="speech"
                id="speech_dysphasia"
                checked={nervousSystem?.speech?.status === 'dysphasia/aphasia'}
                onClick={() => handleChange('speechStatus', 'dysphasia/aphasia')}
              />
            </Col>
          </Row>
        </Col>

        {/* Dropdown & Note */}
        <Col md={4}>
          {/* Dropdown for Dysarthria or Dysphonia */}
          {['dysarthria', 'dysphonia'].includes(nervousSystem?.speech?.status) && (
            <Form.Select
              size="sm"
              className="form-control-sm mb-2"
              style={{ width: '100%' }}
              value={nervousSystem?.speech?.type || ''}
              onChange={(e) => handleChange('speechType', e.target.value)}
            >
              <option value="">Select...</option>
              <option value="slurring speech">Slurring speech</option>
              <option value="slurred speech">Slurred Speech</option>
              <option value="hot potato/mumbling speech">Hot potato/Mumbling Speech</option>
              <option value="nasal intonation">Nasal intonation</option>
              <option value="monotonous">Monotonous</option>
            </Form.Select>
          )}

          {/* Dropdown for Dysphasia/Aphasia */}
          {nervousSystem?.speech?.status === 'dysphasia/aphasia' && (
            <Form.Select
              size="sm"
              className="form-control-sm mb-2"
              style={{ width: '100%' }}
              value={nervousSystem?.speech?.type || ''}
              onChange={(e) => handleChange('speechType', e.target.value)}
            >
              <option value="">Select...</option>
              <option value="motor">Motor</option>
              <option value="sensory">Sensory</option>
              <option value="conduction">Conduction</option>
              <option value="trascahied">Trascahied</option>
              <option value="global">Global</option>
            </Form.Select>
          )}

          {/* Note Input */}
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.speech?.note || ''}
            onChange={(e) => handleChange('speechNote', e.target.value)}
          />
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Cranial Nerves</Col>
      </Row>
      <hr />
      {/* Cranial Nerves */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Cranial Nerves:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Intact"
                name="cranialNerves"
                id="cranialNerves_intact"
                checked={nervousSystem?.cranialNerves?.status === 'intact'}
                onClick={() =>
                  handleChange(
                    'cranialNervesStatus',
                    nervousSystem?.cranialNerves?.status === 'intact'
                      ? ''
                      : 'intact',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Palsy"
                name="cranialNerves"
                id="cranialNerves_palsy"
                checked={nervousSystem?.cranialNerves?.status === 'palsy'}
                onClick={() =>
                  handleChange(
                    'cranialNervesStatus',
                    nervousSystem?.cranialNerves?.status === 'palsy'
                      ? ''
                      : 'palsy',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          {nervousSystem?.cranialNerves?.status === 'palsy' && (
            <Form.Select
              size="sm"
              className="form-control-sm mb-2"
              style={{ width: '100%' }}
              value={nervousSystem?.cranialNerves?.type || ''}
              onChange={(e) =>
                handleChange('cranialNervesType', e.target.value)
              }
            >
              <option value="">Select...</option>
              <option value="cni">CN I</option>
              <option value="cnii">CN II</option>
              <option value="cniii">CN III</option>
              <option value="cniv">CN IV</option>
              <option value="cnv">CN V</option>
              <option value="cnvi">CN VI</option>
              <option value="cnvii">CN VII</option>
              <option value="cnviii">CN VIII</option>
              <option value="cnix">CN IX</option>
              <option value="cnx">CN X</option>
              <option value="cnxi">CN XI</option>
              <option value="cnxii">CN XII</option>
              <option value="cnxiii">CN XIII</option>
              <option value="cnxiv">CN XIV</option>
              <option value="cnxv">CN XV</option>
              <option value="cnxvi">CN XVI</option>
              <option value="cnxvii">CN XVII</option>
              <option value="cnxviii">CN XVIII</option>
              <option value="cnxix">CN XIX</option>
              <option value="cnxx">CN XX</option>
              <option value="cnxxi">CN XXI</option>
              <option value="cnxxii">CN XXII</option>

            </Form.Select>
          )}
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.cranialNerves?.note || ''}
            onChange={(e) => handleChange('cranialNervesNote', e.target.value)}
          />
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Motor System</Col>
      </Row>
      <hr />
      {/* Wasting */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Wasting:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Present"
                name="wasting"
                id="wasting_present"
                checked={nervousSystem?.wasting?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'wastingStatus',
                    nervousSystem?.wasting?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="Absent"
                name="wasting"
                id="wasting_absent"
                checked={nervousSystem?.wasting?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'wastingStatus',
                    nervousSystem?.wasting?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex align-items-center ${
            nervousSystem?.wasting?.status === 'present' ? 'gap-2' : ''
          }`}
        >
          {nervousSystem?.wasting?.status === 'present' && (
            <div className="me-2" style={{ flex: '1' }}>
              <Form.Select
                size="sm"
                className="form-control-sm"
                style={{ width: '90%' }}
                value={nervousSystem?.wasting?.type || ''}
                onChange={(e) => handleChange('wastingType', e.target.value)}
              >
                <option value="">Select...</option>
                <option value="rt ul">Rt UL</option>
                <option value="lt ul">Lt UL</option>
                <option value="rt ll">Rt LL</option>
                <option value="lt ll">Lt LL</option>
              </Form.Select>
            </div>
          )}
          <div style={{ flex: '1' }}>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Add note"
              className="form-control-sm"
              style={{ height: '33px' }}
              value={nervousSystem?.wasting?.note || ''}
              onChange={(e) => handleChange('wastingNote', e.target.value)}
            />
          </div>
        </Col>
      </Row>

      {/* Power */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Power:
        </Col>

        {/* MRC Grade Input */}
        <Col md={5} className="d-flex flex-column align-items-start">
          <Form.Control
            size="sm"
            type="number"
            placeholder="MRC Grade (0 - 5)"
            value={nervousSystem?.power?.value || ''}
            onChange={(e) => handleChange('powerValue', e.target.value)}
          />

          {/* Checkboxes directly below MRC Grade */}
          <Row className="mt-2">
            {powerTypes.map((type, index) => (
              <Col xs={6} key={type} className="mb-1">
                <Form.Check
                  type="checkbox"
                  id={`power-type-${type}`}
                  label={type}
                  checked={(selectedData.power?.type || []).includes(type)}
                  onChange={(e) => handleChange('powerType', type)}
                />
              </Col>
            ))}
          </Row>
        </Col>

        {/* Note Input */}
        <Col md={4} className="d-flex flex-column align-items-start">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px' }}
            value={selectedData.power?.note || ''}
            onChange={(e) => handleChange('powerNote', e.target.value)}
          />
        </Col>
      </Row>



      {/* Jerks */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Jerks:
        </Col>
        <Col md={5}>
          <Row>
            {[
              { label: 'Biceps (Rt)', value: 'biceps rt' },
              { label: 'Biceps (Lt)', value: 'biceps lt' },
              { label: 'Triceps (Rt)', value: 'triceps rt' },
              { label: 'Triceps (Lt)', value: 'triceps lt' },
              { label: 'Supinator (Rt)', value: 'supinator rt' },
              { label: 'Supinator (Lt)', value: 'supinator lt' },
              { label: 'Knee (Rt)', value: 'knee rt' },
              { label: 'Knee (Lt)', value: 'knee lt' },
              { label: 'Ankle (Rt)', value: 'ankle rt' },
              { label: 'Ankle (Lt)', value: 'ankle lt' },
            ].map((option, index) => (
              <Col md={6} className="mb-2" key={index}>
                <Form.Check
                  type="radio"
                  id={`jerks_${option.value}`}
                  label={option.label}
                  name="jerks"
                  checked={nervousSystem?.jerks?.type === option.value}
                  onClick={() => handleChange(
                    'jerksType',
                    nervousSystem?.jerks?.type === option.value ? '' : option.value
                  )}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex flex-column ${nervousSystem?.jerks?.type ? 'gap-2' : ''
            }`}
        >
          {nervousSystem?.jerks?.type && (
            <Form.Select
              size="sm"
              style={{ width: '100%' }}
              className="form-control-sm mb-2"
              value={nervousSystem?.jerks?.status || ''}
              onChange={(e) => handleChange('jerksStatus', e.target.value)}
            >
              <option value="">Select...</option>
              <option value="normal">Normal</option>
              <option value="present with reinforcement">Present with reinforcement</option>
              <option value="reduced">Reduced</option>
              <option value="absent">Absent</option>
              <option value="increased/exaggerated">Increased/exaggerated</option>
              <option value="exaggerated with clonus">Exaggerated with clonus</option>
            </Form.Select>
          )}
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.jerks?.note || ''}
            onChange={(e) => handleChange('jerksNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Plantor Response */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Plantor Response:
        </Col>
        <Col md={5}>
          <Row>
            {[
              { label: 'Flexor', value: 'flexor' },
              { label: 'Extensor', value: 'extensor' },
              { label: 'Equivocal', value: 'equivocal' },
              { label: 'Withdrawal', value: 'withdrawal' },
            ].map((option, index) => (
              <Col md={6} className="mb-2" key={index}>
                <Form.Check
                  type="radio"
                  id={`plantor_${option.value}`}
                  label={option.label}
                  name="plantorResponse"
                  checked={
                    nervousSystem?.plantorResponse?.status === option.value
                  }
                  onClick={() =>
                    handleChange(
                      'plantorResponseStatus',
                      nervousSystem?.plantorResponse?.status === option.value
                        ? ''
                        : option.value,
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex flex-column ${
            nervousSystem?.plantorResponse?.status ? 'gap-2' : ''
          }`}
        >
          {nervousSystem?.plantorResponse?.status && (
            <Form.Select
              size="sm"
              className="form-control-sm"
              style={{ width: '100%' }}
              value={nervousSystem?.plantorResponse?.type || ''}
              onChange={(e) =>
                handleChange('plantorResponseType', e.target.value)
              }
            >
              <option value="">Select...</option>
              <option value="Rt">Rt</option>
              <option value="Lt">Lt</option>
            </Form.Select>
          )}
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.plantorResponse?.note || ''}
            onChange={(e) =>
              handleChange('plantorResponseNote', e.target.value)
            }
          />
        </Col>
      </Row>

      {/* Cerebellar Function */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Cerebellar Function:
        </Col>
        <Col md={5}>
          <Row>
            {[
              { label: 'Intact', value: 'intact' },
              { label: 'Impaired', value: 'impaired' },
            ].map((option, index) => (
              <Col md={6} className="mb-2" key={index}>
                <Form.Check
                  type="radio"
                  id={`cerebellar_${option.value}`}
                  label={option.label}
                  name="cerebellarFunction"
                  checked={
                    nervousSystem?.cerebellarFunction?.status === option.value
                  }
                  onClick={() =>
                    handleChange(
                      'cerebellarFunctionStatus',
                      nervousSystem?.cerebellarFunction?.status === option.value
                        ? ''
                        : option.value,
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex align-items-flex-start ${
            nervousSystem?.cerebellarFunction?.status ? 'gap-2' : ''
          }`}
        >
          {nervousSystem?.cerebellarFunction?.status && (
            <div className="me-2" style={{ flex: '1' }}>
              <Form.Select
                size="sm"
                className="form-control-sm"
                style={{ width: '90%' }}
                value={nervousSystem?.cerebellarFunction?.type || ''}
                onChange={(e) =>
                  handleChange('cerebellarFunctionType', e.target.value)
                }
              >
                <option value="">Select...</option>
                <option value="Rt">Rt</option>
                <option value="Lt">Lt</option>
              </Form.Select>
            </div>
          )}
          <div
            style={{
              flex: nervousSystem?.cerebellarFunction?.status ? '1' : '1',
            }}
          >
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Add note"
              className="form-control-sm"
              style={{ height: '33px' }}
              value={nervousSystem?.cerebellarFunction?.note || ''}
              onChange={(e) =>
                handleChange('cerebellarFunctionNote', e.target.value)
              }
            />
          </div>
        </Col>
      </Row>

      {/* Sensory System */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Sensory System:
        </Col>
        <Col md={5}>
          <Row>
            {[
              {
                label: 'Pain/Temperature Sensation',
                value: 'pain/temperature sensation',
              },
              {
                label: 'Touch/Pressure Sensation',
                value: 'touch/pressure sensation',
              },
              { label: 'Sense of Position', value: 'sense of position' },
              { label: 'Sense of Vibration', value: 'sense of vibration' },
              { label: 'Rombergism', value: 'rombergism' },
            ].map((option, index) => (
              <Col md={12} className="mb-2" key={index}>
                <Form.Check
                  type="radio"
                  id={`sensory_${option.value}`}
                  label={option.label}
                  name="sensorySystem"
                  checked={
                    nervousSystem?.sensorySystem?.status === option.value
                  }
                  onClick={() =>
                    handleChange(
                      'sensorySystemStatus',
                      nervousSystem?.sensorySystem?.status === option.value
                        ? ''
                        : option.value,
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex flex-column ${
            nervousSystem?.sensorySystem?.status ? 'gap-2' : ''
          }`}
        >
          {/* Dropdown for options */}
          {nervousSystem?.sensorySystem?.status && (
            <Form.Select
              size="sm"
              className="form-control-sm"
              style={{ width: '100%' }}
              value={nervousSystem?.sensorySystem?.type || ''}
              onChange={(e) =>
                handleChange('sensorySystemType', e.target.value)
              }
            >
              <option value="">Select...</option>
              {/* Conditional dropdown options */}
              {nervousSystem.sensorySystem.status === 'rombergism' ? (
                <>
                  <option value="present">Present</option>
                  <option value="absent">Absent</option>
                </>
              ) : (
                <>
                  <option value="rt upper limb - Intact">
                    Rt upper limb - Intact
                  </option>
                  <option value="rt upper limb - Impaired">
                    Rt upper limb - Impaired
                  </option>
                  <option value="rt upper limb - Absent">
                    Rt upper limb - Absent
                  </option>
                  <option value="lt upper limb - Intact">
                    Lt upper limb - Intact
                  </option>
                  <option value="lt upper limb - Impaired">
                    Lt upper limb - Impaired
                  </option>
                  <option value="lt upper limb - Absent">
                    Lt upper limb - Absent
                  </option>
                </>
              )}
            </Form.Select>
          )}
          {/* Textarea for notes */}
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px' }}
            value={nervousSystem?.sensorySystem?.note || ''}
            onChange={(e) => handleChange('sensorySystemNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Gait */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Gait:
        </Col>
        <Col md={5}>
          <Row>
            {[
              { label: 'Normal', value: 'normal' },
              { label: 'Abnormal', value: 'abnormal' },
            ].map((option, index) => (
              <Col md={6} className="mb-2" key={index}>
                <Form.Check
                  type="radio"
                  id={`gait_${option.value}`}
                  label={option.label}
                  name="gaitFunction"
                  checked={nervousSystem?.gaitFunction?.status === option.value}
                  onClick={() =>
                    handleChange(
                      'gaitFunctionStatus',
                      nervousSystem?.gaitFunction?.status === option.value
                        ? ''
                        : option.value,
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          md={4}
          className={`d-flex align-items-flex-start ${
            nervousSystem?.gaitFunction?.status ? 'gap-2' : ''
          }`}
        >
          {nervousSystem?.gaitFunction?.status === 'abnormal' && (
            <div className="me-2" style={{ flex: '1' }}>
              <Form.Select
                size="sm"
                className="form-control-sm"
                style={{ width: '90%' }}
                value={nervousSystem?.gaitFunction?.type || ''}
                onChange={(e) =>
                  handleChange('gaitFunctionType', e.target.value)
                }
              >
                <option value="">Select...</option>
                <option value="Antalgic gait">Antalgic gait</option>
                <option value="Hemiplegic gait">Hemiplegic gait</option>
                <option value="Scissar gait">Scissar gait</option>
                <option value="Waddling gait">Waddling gait</option>
                <option value="High Steppage gait">High Steppage gait</option>
                <option value="Afaxic gait">Afaxic gait</option>
                <option value="Festinant gait">Festinant gait</option>
                <option value="Spastic gait">Spastic gait</option>
              </Form.Select>
            </div>
          )}
          <div
            style={{ flex: nervousSystem?.gaitFunction?.status ? '1' : '1' }}
          >
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Add note"
              className="form-control-sm"
              style={{ height: '33px' }}
              value={nervousSystem?.gaitFunction?.note || ''}
              onChange={(e) => handleChange('gaitFunctionNote', e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Signs of Meniaged Imitation</Col>
      </Row>
      <hr />

      {/* Neck Rigidity */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Neck Rigidity:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="neck_rigidity_present"
                name="neckRigidity"
                label="Present"
                checked={nervousSystem?.neckRigidity?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'neckRigidityStatus',
                    nervousSystem?.neckRigidity?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="neck_rigidity_absent"
                name="neckRigidity"
                label="Absent"
                checked={nervousSystem?.neckRigidity?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'neckRigidityStatus',
                    nervousSystem?.neckRigidity?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.neckRigidity?.note || ''}
            onChange={(e) => handleChange('neckRigidityNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Kernig Sign */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Kernig Sign:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="kernig_sign_present"
                name="kernigSign"
                label="Present"
                checked={nervousSystem?.kernigSign?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'kernigSignStatus',
                    nervousSystem?.kernigSign?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="kernig_sign_absent"
                name="kernigSign"
                label="Absent"
                checked={nervousSystem?.kernigSign?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'kernigSignStatus',
                    nervousSystem?.kernigSign?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.kernigSign?.note || ''}
            onChange={(e) => handleChange('kernigSignNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Brudzinski Sign */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Brudzinski Sign:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="brudzinski_sign_present"
                name="brudzinskiSign"
                label="Present"
                checked={nervousSystem?.brudzinskiSign?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'brudzinskiSignStatus',
                    nervousSystem?.brudzinskiSign?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="brudzinski_sign_absent"
                name="brudzinskiSign"
                label="Absent"
                checked={nervousSystem?.brudzinskiSign?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'brudzinskiSignStatus',
                    nervousSystem?.brudzinskiSign?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.brudzinskiSign?.note || ''}
            onChange={(e) => handleChange('brudzinskiSignNote', e.target.value)}
          />
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Cardiovascular System</Col>
      </Row>
      <hr />

      {/* JVP */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          JVP:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="jvp_normal"
                name="jvp"
                label="Normal"
                checked={nervousSystem?.jvp?.status === 'normal'}
                onClick={() =>
                  handleChange(
                    'jvpStatus',
                    nervousSystem?.jvp?.status === 'normal' ? '' : 'normal',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="jvp_raised"
                name="jvp"
                label="Raised"
                checked={nervousSystem?.jvp.status === 'raised'}
                onClick={() =>
                  handleChange(
                    'jvpStatus',
                    nervousSystem?.jvp?.status === 'raised' ? '' : 'raised',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.jvp?.note || ''}
            onChange={(e) => handleChange('jvpNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Pulse */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Pulse:
        </Col>
        <Col md={5}>
          <Row className="align-items-flex-start">
            <Col md={6}>
              <Form.Check
                type="radio"
                id="pulse_regular"
                label="Regular"
                name="pulse"
                checked={nervousSystem?.pulse?.status === 'regular'}
                onClick={() =>
                  handleChange(
                    'pulseStatus',
                    nervousSystem?.pulse?.status === 'regular' ? '' : 'regular',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="pulse_irregular"
                label="Irregular"
                name="pulse"
                checked={nervousSystem?.pulse?.status === 'irregular'}
                onClick={() =>
                  handleChange(
                    'pulseStatus',
                    nervousSystem?.pulse?.status === 'irregular'
                      ? ''
                      : 'irregular',
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <Form.Check
                type="radio"
                id="pulse_drop_beat"
                label="Drop Beat"
                name="pulse"
                checked={nervousSystem?.pulse?.status === 'drop beat'}
                onClick={() =>
                  handleChange(
                    'pulseStatus',
                    nervousSystem?.pulse?.status === 'drop beat'
                      ? ''
                      : 'drop beat',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="pulse_absent"
                label="Absent"
                name="pulse"
                checked={nervousSystem?.pulse?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'pulseStatus',
                    nervousSystem?.pulse?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.pulse?.note || ''}
            onChange={(e) => handleChange('pulseNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Murmur */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Murmur:
        </Col>
        <Col md={5}>
          <Row className="align-items-flex-start">
            <Col md={6}>
              <Form.Check
                type="radio"
                id="murmur_present"
                label="Present"
                name="murmur"
                checked={nervousSystem?.murmur?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'murmurStatus',
                    nervousSystem?.murmur?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="murmur_absent"
                label="Absent"
                name="murmur"
                checked={nervousSystem?.murmur?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'murmurStatus',
                    nervousSystem?.murmur?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.murmur?.note || ''}
            onChange={(e) => handleChange('murmurNote', e.target.value)}
          />
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Gastrointestinal System</Col>
      </Row>
      <hr />
      {/* Ascitis */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Ascitis:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="ascitis_present"
                name="ascitis"
                label="Present"
                checked={nervousSystem?.ascitis?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'ascitisStatus',
                    nervousSystem?.ascitis?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="ascitis_absent"
                name="ascitis"
                label="Absent"
                checked={nervousSystem?.ascitis?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'ascitisStatus',
                    nervousSystem?.ascitis?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.ascitis?.note || ''}
            onChange={(e) => handleChange('ascitisNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Hepatomegaly */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Hepatomegaly:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="hepatomegaly_present"
                name="hepatomegaly"
                label="Present"
                checked={nervousSystem?.hepatomegaly?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'hepatomegalyStatus',
                    nervousSystem?.hepatomegaly?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="hepatomegaly_absent"
                name="hepatomegaly"
                label="Absent"
                checked={nervousSystem?.hepatomegaly?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'hepatomegalyStatus',
                    nervousSystem?.hepatomegaly?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.hepatomegaly?.note || ''}
            onChange={(e) => handleChange('hepatomegalyNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Splenomegaly */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Splenomegaly:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="splenomegaly_present"
                name="splenomegaly"
                label="Present"
                checked={nervousSystem?.splenomegaly?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'splenomegalyStatus',
                    nervousSystem?.splenomegaly?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="splenomegaly_absent"
                name="splenomegaly"
                label="Absent"
                checked={nervousSystem?.splenomegaly?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'splenomegalyStatus',
                    nervousSystem?.splenomegaly?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.splenomegaly?.note || ''}
            onChange={(e) => handleChange('splenomegalyNote', e.target.value)}
          />
        </Col>
      </Row>

      <Row className="title mt-3">
        <Col>Respiratory System</Col>
      </Row>
      <hr />
      {/* RR */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          RR:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="rr_minimum"
                name="rr"
                label="Minimum"
                checked={nervousSystem?.rr?.status === 'minimum'}
                //onChange={() => handleChange('rrStatus', 'minimum')}
                onClick={() =>
                  handleChange(
                    'rrStatus',
                    nervousSystem?.rr?.status === 'minimum' ? '' : 'minimum',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="rr_regular"
                name="rr"
                label="Regular"
                checked={nervousSystem?.rr?.status === 'regular'}
                //onChange={() => handleChange('rrStatus', 'regular')}
                onClick={() =>
                  handleChange(
                    'rrStatus',
                    nervousSystem?.rr?.status === 'regular' ? '' : 'regular',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.rr?.note || ''}
            onChange={(e) => handleChange('rrNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Rhanchi */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Rhanchi:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="rhanchi_present"
                name="rhanchi"
                label="Present"
                checked={nervousSystem?.rhanchi?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'rhanchiStatus',
                    nervousSystem?.rhanchi?.status === 'present'
                      ? ''
                      : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="rhanchi_absent"
                name="rhanchi"
                label="Absent"
                checked={nervousSystem?.rhanchi?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'rhanchiStatus',
                    nervousSystem?.rhanchi?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.rhanchi?.note || ''}
            onChange={(e) => handleChange('rhanchiNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Creps */}
      <Row className="mb-3 align-items-center">
        <Col md={3} className="label-text">
          Creps:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="creps_present"
                name="creps"
                label="Present"
                checked={nervousSystem?.creps?.status === 'present'}
                onClick={() =>
                  handleChange(
                    'crepsStatus',
                    nervousSystem?.creps?.status === 'present' ? '' : 'present',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="creps_absent"
                name="creps"
                label="Absent"
                checked={nervousSystem?.creps?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'crepsStatus',
                    nervousSystem?.creps?.status === 'absent' ? '' : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.creps?.note || ''}
            onChange={(e) => handleChange('crepsNote', e.target.value)}
          />
        </Col>
      </Row>

      {/* Breath Sound */}
      <Row className="mb-3 align-items-flex-start">
        <Col md={3} className="label-text">
          Breath Sound:
        </Col>
        <Col md={5}>
          <Row>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="breath_sound_normal"
                name="breathSound"
                label="Normal"
                checked={nervousSystem?.breathSound?.status === 'normal'}
                onClick={() =>
                  handleChange(
                    'breathSoundStatus',
                    nervousSystem?.breathSound?.status === 'normal'
                      ? ''
                      : 'normal',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="breath_sound_bronchial"
                name="breathSound"
                label="Bronchial"
                checked={nervousSystem?.breathSound?.status === 'bronchial'}
                onClick={() =>
                  handleChange(
                    'breathSoundStatus',
                    nervousSystem?.breathSound?.status === 'bronchial'
                      ? ''
                      : 'bronchial',
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <Form.Check
                type="radio"
                id="breath_sound_reduced"
                name="breathSound"
                label="Reduced"
                checked={nervousSystem?.breathSound?.status === 'reduced'}
                onClick={() =>
                  handleChange(
                    'breathSoundStatus',
                    nervousSystem?.breathSound?.status === 'reduced'
                      ? ''
                      : 'reduced',
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="radio"
                id="breath_sound_absent"
                name="breathSound"
                label="Absent"
                checked={nervousSystem?.breathSound?.status === 'absent'}
                onClick={() =>
                  handleChange(
                    'breathSoundStatus',
                    nervousSystem?.breathSound?.status === 'absent'
                      ? ''
                      : 'absent',
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add note"
            className="form-control-sm"
            style={{ height: '33px', width: '100%' }}
            value={nervousSystem?.breathSound?.note || ''}
            onChange={(e) => handleChange('breathSoundNote', e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};

export default NeurologyExamination;
