import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { handleGroupData } from '../../../utils/helpers';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import SearchArea from '../partials/searchAreaAsync';
import TabNav from '../partials/tabNav';
import SearchItemSuggestions from '../searchItemSuggestions';
import InvestigationGroupForm from './investigationGroupForm';

const InvestigationModal = ({
  modalTitle,
  selectedInvestigations,
  setSelectedInvestigations,
  selectedInvestigationGroups,
  setSelectedInvestigationGroups,
  showInvestigation,
  setShowInvestigation,
  selectedHistories,
  setSelectedHistories,
  isPrescriptionExist,
}) => {
  const [investigationList, setInvestigationList] = useState([]);
  const [units, setUnits] = useState([]);
  const [investigationsInSearch, setInvestigationsInSearch] = useState([]);
  const [investigationGroups, setInvestigationGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const perPage = 25;

  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  let selectedData =
    selectedHistories && Object.keys(selectedHistories).length !== 0
      ? selectedHistories
      : {
          medical: [],
          drugs: {
            D_H: [],
            D_A: [],
          },
          investigations: [],
          personal: {
            smoker: false,
            alcoholic: false,
            tobacco: false,
            notes: '',
          },
          family: '',
        };

  let { investigations } = selectedData;
  investigations = Array.isArray(investigations)
    ? selectedData?.investigations
    : [];

  const mergeInvestigationGroup = (data) => {
    let newInvestigation = [];
    data.investigationIds.map((itemId) => {
      let investigationItem = investigationList.filter(
        (inv) => inv.id === itemId,
      );
      if (investigationItem.length) {
        newInvestigation = [...newInvestigation, ...investigationItem];
      }
    });
    let newGroup = {
      id: data.id,
      name: data.name,
      doctorId: data.doctorId,
      Investigations: newInvestigation,
    };
    setInvestigationGroups([...investigationGroups, newGroup]);
  };
  const mergeInvestigation = (data) => {
    setInvestigationList([...investigationList, data]);
  };

  const { isLoading: isUnitLoading, refetch: getUnits } = useGetRequest(
    'getUnit',
    `units`,
    (res) => {
      setUnits(res.data);
    },
    (e) => {
      console.log(e);
    },
  );

  const { isLoading: isAdviceLoading, refetch: getInvestigations } =
    useGetRequest(
      'getInvestigation',
      `investigations?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setInvestigationList([...investigationList, ...data.data]);
        } else {
          setInvestigationList(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const getInvestigationGroups = async () => {
    await getRequest('investigations/groups')
      .then((data) => {
        setInvestigationGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectInvestigation = (item) => {
    const isInvestigationExist = selectedInvestigations.some(
      (investigation) => investigation.name === item.name,
    );
    const updatedInvestigations = isInvestigationExist
      ? selectedInvestigations.filter(
          (investigation) => investigation.name !== item.name,
        )
      : [
          ...selectedInvestigations,
          { name: item.name, side: '', instruction: '', result: '', unit: '' },
        ];
    setSelectedInvestigations(updatedInvestigations);
  };

  const deleteInvestigation = async (investigationId) => {
    await deleteRequest(`investigations/${investigationId}`)
      .then((data) => {
        setInvestigationList(
          investigationList.filter(
            (investigation) => investigation.id !== investigationId,
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectInvestigationWithInstruction = (item) => {
    const isInvestigationExist = selectedInvestigations.some(
      (investigation) => investigation.name === item.name,
    );
    const updatedInvestigations = isInvestigationExist
      ? selectedInvestigations.map((investigation) =>
          investigation.name !== item.name
            ? investigation
            : { ...investigation, instruction: item?.note },
        )
      : [
          ...selectedInvestigations,
          { name: item.name, instruction: item?.note, result: '', unit: '' },
        ];
    setSelectedInvestigations(updatedInvestigations);
  };

  const deleteInvestigationGroup = async (groupId) => {
    await deleteRequest(`investigations/groups/${groupId}`)
      .then((data) => {
        setInvestigationGroups(
          investigationGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectInvestigationGroup = (group) => {
    handleGroupData(
      group,
      selectedInvestigationGroups,
      setSelectedInvestigationGroups,
      selectedInvestigations,
      setSelectedInvestigations,
      'Investigation group',
    );
  };

  const removeInvestigation = (item) => {
    setSelectedInvestigations(
      selectedInvestigations.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleInvestigationExtras = (index, fieldName, val) => {
    selectedInvestigations[index][fieldName] = val;
    setSelectedInvestigations([...selectedInvestigations]);
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getInvestigationSuggestions',
      `prescriptions/suggestions?suggestion_type=investigation`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  useEffect(() => {
    if (showInvestigation) {
      getUnits();
      getSuggestions();
      getInvestigationGroups();
    } else {
      setSearchQuery('');
      setInvestigationsInSearch([]);
    }
  }, [showInvestigation]);

  useEffect(() => {
    getInvestigations();
  }, [currentPage]);

  const handleInvestigationUnit = (index, option) => {
    selectedInvestigations[index]['unit'] = option;
    setSelectedInvestigations([...selectedInvestigations]);
    setSearchTerm(option);
    setFilteredOptions([]);
  };

  const handleInputChange = (index, fieldName, value) => {
    const filtered = units?.filter((item) => item?.name?.includes(value));
    const updatedInvestigations = [...selectedInvestigations];
    updatedInvestigations[index][fieldName] = value;

    setSelectedInvestigations(updatedInvestigations);
    setFilteredOptions(filtered);
  };

  const selectedInvestigationList = () => {
    return selectedInvestigations?.map((item, index) => {
      return (
        // <SingleInvestigation
        //   key={index}
        //   item={item}
        //   index={index}
        //   handleInvestigationExtras={handleInvestigationExtras}
        //   removeInvestigation={removeInvestigation}
        //   inputRefs={inputRefs}
        //   handleFocus={handleFocus}
        //   selectedIndexForFocus={selectedIndexForFocus}
        // />
        <Row className="selected-item-row" key={index}>
          <Col lg={3} md={3} sm={3} xs={12}>
            {item.name}
          </Col>
          <Col lg={2} md={2} sm={2} xs={3} className="pl-0">
            <Form.Select
              className="form-control form-control-sm"
              defaultValue={item.side}
              onChange={(e) =>
                handleInvestigationExtras(index, 'side', e.target.value)
              }
            >
              <option value="">Side</option>
              <option value={'rt'}>RT</option>
              <option value={'lt'}>LT</option>
              <option value={'bil'}>BIL</option>
            </Form.Select>
          </Col>
          <Col lg={2} md={2} sm={4} xs={4} className="pl-0 responsive">
            <Form.Control
              size="sm"
              min={0}
              type="text"
              defaultValue={item.instruction}
              placeholder="Add Instruction"
              onChange={
                (e) =>
                  handleInvestigationExtras(
                    index,
                    'instruction',
                    e.target.value,
                  )
                //setInsturction(e.target.value)
              }
            />
          </Col>
          <Col lg={2} md={2} sm={4} xs={4} className="pl-0 responsive">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.result}
              placeholder="Add Result"
              onChange={(e) =>
                handleInvestigationExtras(index, 'result', e.target.value)
              }
            />
          </Col>
          <Col lg={2} md={2} sm={3} xs={3} className="inv-parent pl-0 pr-0">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item?.unit}
              placeholder="Search/Add unit"
              onChange={(e) => handleInputChange(index, 'unit', e.target.value)}
            />
            {filteredOptions?.length > 0 && (
              <div className="inv-unit">
                {filteredOptions?.map((option, key) => (
                  <div
                    key={key}
                    onClick={() => handleInvestigationUnit(option.name)}
                  >
                    {option.name}
                  </div>
                ))}
              </div>
            )}
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right pl-0">
            <i
              className="fa fa-times-circle pt-1"
              aria-hidden="true"
              onClick={() => removeInvestigation(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  let allInvestigations = investigationList.map((item, index) => {
    let isSelected = selectedInvestigations.some(
      (data) => data.name === item.name,
    );
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectInvestigation}
        removeClickAction={deleteInvestigation}
        suggestions={suggestions}
        selectItemWithNote={selectInvestigationWithInstruction}
      />
    );
  });

  const allInvestigationGroup = investigationGroups.map((group, index) => {
    let isSelected = selectedInvestigationGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectInvestigationGroup}
        removeClickAction={deleteInvestigationGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('investigations', {
          name: selectedData,
        });
        setSearchQuery('');
        setInvestigationList([...investigationList, data]);
        setSelectedInvestigations([
          ...selectedInvestigations,
          {
            name: selectedData,
            side: '',
            instruction: '',
            result: '',
            unit: '',
          },
        ]);
      } else {
        const isExisting = selectedInvestigations?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );
        setSelectedInvestigations(
          isExisting
            ? selectedInvestigations?.filter(
                ({ name }) =>
                  name?.toLowerCase() !== selectedData?.toLowerCase(),
              )
            : [
                ...selectedInvestigations,
                {
                  name: selectedData,
                  side: '',
                  instruction: '',
                  result: '',
                  unit: '',
                },
              ],
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = async (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setInvestigationsInSearch([]);
      const url = `investigations?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      await getRequest(url)
        .then((data) => {
          setInvestigationsInSearch(data?.data);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const moveToHistory = () => {
    investigations = [...investigations, ...selectedInvestigations];
    setSelectedHistories({
      ...selectedData,
      investigations,
    });
    setSelectedInvestigations([]);
  };

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = investigationList.find(
      (observationItem) => observationItem.name === itemName,
    );
    item['note'] = note;
    const isInvestigationExist = selectedInvestigations.some(
      (investigation) => investigation.name === item.name,
    );
    const updatedInvestigations = isInvestigationExist
      ? selectedInvestigations.map((investigation) =>
          investigation.name !== item.name
            ? investigation
            : { ...investigation, instruction: item?.note },
        )
      : [
          ...selectedInvestigations,
          { name: item.name, instruction: item?.note, result: '', unit: '' },
        ];
    setSelectedInvestigations(updatedInvestigations);
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  return (
    <Modal
      show={showInvestigation}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowInvestigation} /> */}
      <Modal.Body>
        <SearchArea
          modalTitle={modalTitle}
          closeAction={setShowInvestigation}
          setSearchOptions={setInvestigationsInSearch}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={investigationsInSearch}
          selectedInputs={selectedInvestigations}
          placeholder={'investigation'}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedInvestigations}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allInvestigations}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {allInvestigationGroup}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className="selected-hr" />
        <div className="selected-item-title d-flex justify-content-between">
          <span>Selected list</span>
          {isPrescriptionExist && selectedInvestigations.length > 0 && (
            <span
              className="move-to-history-btn"
              onClick={() => moveToHistory()}
            >
              Move To History
            </span>
          )}
        </div>
        <div className="selected-item-area inv-selected-area">
          {selectedInvestigationList()}
        </div>

        <InvestigationGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedInvestigations.map((item) => item.name)}
          selectedInvestigationGroups={selectedInvestigationGroups}
          setSelectedInvestigationGroups={setSelectedInvestigationGroups}
          investigationGroups={investigationGroups}
          setInvestigationGroups={setInvestigationGroups}
        />

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(InvestigationModal);
