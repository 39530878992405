import { styledComponent } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const NervousSystem = ({
  selectedOnExamination,
  setSelectedOnExamination,
  prescriptionItems,
  isDelBtn = true,
}) => {
  let { nervousSystem } = selectedOnExamination || {};

  console.log(nervousSystem, "nervousSystem112")

  const deleteData = (itemName) => {
    if (!nervousSystem) return;

    switch (itemName) {
      case 'consciousness.status':
        nervousSystem.consciousness.status = '';
        break;
      case 'gcs.value':
        nervousSystem.gcs.value = '';
        break;
      case 'gcs.note':
        nervousSystem.gcs.note = '';
        break;
      case 'consciousness.note':
        nervousSystem.consciousness.note = '';
        break;
      case 'delusion.status':
        nervousSystem.delusion.status = '';
        break;
      case 'delusion.note':
        nervousSystem.delusion.note = '';
        break;
      case 'orientation':
        nervousSystem.orientation.status = '';
        nervousSystem.orientation.type = '';
        break;
      case 'orientation.note':
        nervousSystem.orientation.note = '';
        break;
      case 'hallucination':
        nervousSystem.hallucination.status = '';
        nervousSystem.hallucination.type = '';
        break;
      case 'hallucination.note':
        nervousSystem.hallucination.note = '';
        break;
      case 'memory':
        nervousSystem.memory.status = '';
        nervousSystem.memory.type = '';
        break;
      case 'memory.note':
        nervousSystem.memory.note = '';
        break;
      case 'mmse.value':
        nervousSystem.mmse.value = '';
        break;
      case 'mmse.note':
        nervousSystem.mmse.note = '';
        break;

      case 'speech':
        nervousSystem.speech.status = '';
        nervousSystem.speech.type = '';
        break;
      case 'speech.note':
        nervousSystem.speech.note = '';
        break;

      case 'cranialNerves':
        nervousSystem.cranialNerves.status = '';
        nervousSystem.cranialNerves.type = '';
        break;
      case 'cranialNerves.note':
        nervousSystem.cranialNerves.note = '';
        break;
      case 'wasting':
        nervousSystem.wasting.status = '';
        nervousSystem.wasting.type = '';
        break;
      case 'wasting.note':
        nervousSystem.wasting.note = '';
        break;

      case 'power':
        nervousSystem.power.value = '';
        nervousSystem.power.type = '';
        break;
      case 'power.note':
        nervousSystem.power.note = '';
        break;

      case 'jerks':
        nervousSystem.jerks.status = '';
        nervousSystem.jerks.type = '';
        break;
      case 'jerks.note':
        nervousSystem.jerks.note = '';
        break;

      case 'plantorResponse':
        nervousSystem.plantorResponse.status = '';
        nervousSystem.plantorResponse.type = '';
        break;
      case 'plantorResponse.note':
        nervousSystem.plantorResponse.note = '';
        break;

      case 'cerebellarFunction':
        nervousSystem.cerebellarFunction.status = '';
        nervousSystem.cerebellarFunction.type = '';
        break;
      case 'cerebellarFunction.note':
        nervousSystem.cerebellarFunction.note = '';
        break;

      case 'sensorySystem':
        nervousSystem.sensorySystem.status = '';
        nervousSystem.sensorySystem.type = '';
        break;
      case 'sensorySystem.note':
        nervousSystem.sensorySystem.note = '';
        break;

      case 'gaitFunction':
        nervousSystem.gaitFunction.status = '';
        nervousSystem.gaitFunction.type = '';
        break;
      case 'gaitFunction.note':
        nervousSystem.gaitFunction.note = '';
        break;

      case 'neckRigidity.status':
        nervousSystem.neckRigidity.status = '';
        break;
      case 'neckRigidity.note':
        nervousSystem.neckRigidity.note = '';
        break;

      case 'kernigSign.status':
        nervousSystem.kernigSign.status = '';
        break;
      case 'kernigSign.note':
        nervousSystem.kernigSign.note = '';
        break;

      case 'brudzinskiSign.status':
        nervousSystem.brudzinskiSign.status = '';
        break;
      case 'brudzinskiSign.note':
        nervousSystem.brudzinskiSign.note = '';
        break;

      case 'jvp.status':
        nervousSystem.jvp.status = '';
        break;
      case 'jvp.note':
        nervousSystem.jvp.note = '';
        break;
      case 'pulse.status':
        nervousSystem.pulse.status = '';
        break;
      case 'pulse.note':
        nervousSystem.pulse.note = '';
        break;
      case 'murmur.status':
        nervousSystem.murmur.status = '';
        break;
      case 'murmur.note':
        nervousSystem.murmur.note = '';
        break;

      case 'ascitis.status':
        nervousSystem.ascitis.status = '';
        break;
      case 'ascitis.note':
        nervousSystem.ascitis.note = '';
        break;
      case 'hepatomegaly.status':
        nervousSystem.hepatomegaly.status = '';
        break;
      case 'hepatomegaly.note':
        nervousSystem.hepatomegaly.note = '';
        break;
      case 'splenomegaly.status':
        nervousSystem.splenomegaly.status = '';
        break;
      case 'splenomegaly.note':
        nervousSystem.splenomegaly.note = '';
        break;

      case 'rr.status':
        nervousSystem.rr.status = '';
        break;
      case 'rr.note':
        nervousSystem.rr.note = '';
        break;
      case 'rhanchi.status':
        nervousSystem.rhanchi.status = '';
        break;
      case 'rhanchi.note':
        nervousSystem.rhanchi.note = '';
        break;
      case 'creps.status':
        nervousSystem.creps.status = '';
        break;
      case 'creps.note':
        nervousSystem.creps.note = '';
        break;
      case 'breathSound.status':
        nervousSystem.breathSound.status = '';
        break;
      case 'breathSound.note':
        nervousSystem.breathSound.note = '';
        break;
      default:
    }
    selectedOnExamination.nervousSystem = nervousSystem;
    setSelectedOnExamination({ ...selectedOnExamination });
  };


  const Title = styledComponent(
    prescriptionItems?.items?.['on-examination']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['on-examination']?.subItemStyle || {},
  );



  return (
    <div className="breast-exam-view">
      <ul>

        {/* Render GCS */}
        {nervousSystem?.gcs?.value && (
          <li>
            <span className="text-left pr-5">
              <Title>GCS: </Title>
              <Value>{nervousSystem.gcs.value}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('gcs.value')}
              itemName="gcs.value"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render GCS with Note */}
        {nervousSystem?.gcs?.value && nervousSystem?.gcs?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.gcs.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('gcs.note')}
              itemName="gcs.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.gcs?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>GCS: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.gcs.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('gcs.note')}
                itemName="gcs.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Consciousness Level */}
        {nervousSystem?.consciousness?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Level of Consciousness: </Title>
              <Value>{nervousSystem.consciousness.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('consciousness.status')}
              itemName="consciousness.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Consciousness Level with Note */}
        {nervousSystem?.consciousness?.status && nervousSystem?.consciousness?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.consciousness.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('consciousness.note')}
              itemName="consciousness.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.consciousness?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Level of Consciousness: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.consciousness.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('consciousness.note')}
                itemName="consciousness.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Orientation */}
        {(nervousSystem?.orientation?.status || nervousSystem?.orientation?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Orientation: </Title>
              <Value>
                {nervousSystem.orientation.status}
                {/* Only render the type if status is 'lost' */}
                {nervousSystem.orientation.status === 'lost' && nervousSystem.orientation.type ? ` - ${nervousSystem.orientation.type}` : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {(nervousSystem.orientation.status || nervousSystem.orientation.type) && (
              <CommonDeleteBtn
                action={() => deleteData('orientation')}
                itemName="orientation"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.orientation.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.orientation.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('orientation.note')}
                  itemName="orientation.note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}

        {/* Render Delusion Level */}
        {nervousSystem?.delusion?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Delusion: </Title>
              <Value>{nervousSystem.delusion.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('delusion.status')}
              itemName="delusion.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Delusion Level with Note */}
        {nervousSystem?.delusion?.status && nervousSystem?.delusion?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.delusion.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('delusion.note')}
              itemName="delusion.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.delusion?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Delusion: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.delusion.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('delusion.note')}
                itemName="delusion.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Hallucination */}
        {(nervousSystem?.hallucination?.status || nervousSystem?.hallucination?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Hallucination: </Title>
              <Value>
                {nervousSystem.hallucination.status}
                {/* Only render the type if status is 'present' */}
                {nervousSystem.hallucination.status === 'present' && nervousSystem.hallucination.type ? ` - ${nervousSystem.hallucination.type}` : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {(nervousSystem.hallucination.status || nervousSystem.hallucination.type) && (
              <CommonDeleteBtn
                action={() => deleteData('hallucination')}
                itemName="hallucination"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.hallucination.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.hallucination.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('hallucination.note')}
                  itemName="hallucination.note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Memory */}
        {(nervousSystem?.memory?.status || nervousSystem?.memory?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Memory: </Title>
              <Value>
                {nervousSystem.memory.status}
                {/* Only render the type if status is 'lost' */}
                {nervousSystem.memory.status === 'lost' && nervousSystem.memory.type ? ` - ${nervousSystem.memory.type}` : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {(nervousSystem.memory.status || nervousSystem.memory.type) && (
              <CommonDeleteBtn
                action={() => deleteData('memory')}
                itemName="memory"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.memory.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.memory.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('memory.note')}
                  itemName="memory.note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}



        {/* Render MMSE */}
        {nervousSystem?.mmse?.value && (
          <li>
            <span className="text-left pr-5">
              <Title>MMSE: </Title>
              <Value>{nervousSystem.mmse.value}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('mmse.value')}
              itemName="mmse.value"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render MMSE with Note */}
        {nervousSystem?.mmse?.value && nervousSystem?.mmse?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.mmse.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('mmse.note')}
              itemName="mmse.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.mmse?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>MMSE: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.mmse.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('mmse.note')}
                itemName="mmse.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Speech */}
        {(nervousSystem?.speech?.status || nervousSystem?.speech?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Speech: </Title>
              <Value>
                {nervousSystem.speech.status}
                {/* Render type only if status exists */}
                {nervousSystem.speech.status && nervousSystem.speech.type
                  ? ` - ${nervousSystem.speech.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {nervousSystem.speech.status && (
              <CommonDeleteBtn
                action={() => deleteData('speech')}
                itemName="speech"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.speech.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.speech.note}</Value>
                </span>

                {/* Delete button for note */}
                <CommonDeleteBtn
                  action={() => deleteData('speech.note')}
                  itemName="speech note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}

        {/* Render Cranial Nerves */}
        {(nervousSystem?.cranialNerves?.status || nervousSystem?.cranialNerves?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Cranial Nerves: </Title>
              <Value>
                {nervousSystem.cranialNerves.status}
                {/* Only render the type if status is 'palsy' */}
                {nervousSystem.cranialNerves.status === 'palsy' && nervousSystem.cranialNerves.type
                  ? ` - ${nervousSystem.cranialNerves.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {(nervousSystem.cranialNerves.status || nervousSystem.cranialNerves.type) && (
              <CommonDeleteBtn
                action={() => deleteData('cranialNerves')}
                itemName="cranial nerves"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.cranialNerves.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.cranialNerves.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('cranialNerves.note')}
                  itemName="cranial nerves note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Wasting */}
        {(nervousSystem?.wasting?.status || nervousSystem?.wasting?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Wasting: </Title>
              <Value>
                {nervousSystem.wasting.status}
                {/* Only render the type if status is 'present' */}
                {nervousSystem.wasting.status === 'present' && nervousSystem.wasting.type
                  ? ` - ${nervousSystem.wasting.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {(nervousSystem.wasting.status || nervousSystem.wasting.type) && (
              <CommonDeleteBtn
                action={() => deleteData('wasting')}
                itemName="wasting"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.wasting.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.wasting.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('wasting.note')}
                  itemName="wasting note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Power */}
        {(nervousSystem?.power?.value || nervousSystem?.power?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Power: </Title>
              <Value>
                {nervousSystem.power.value}
                {/* Render type only if value exists */}
                {nervousSystem.power.value && nervousSystem.power.type?.length > 0
                  ? ` - ${nervousSystem.power.type.join(', ')}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for value and type */}
            {nervousSystem.power.value && (
              <CommonDeleteBtn
                action={() => deleteData('power')}
                itemName="power"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.power.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.power.note}</Value>
                </span>

                {/* Delete button for note */}
                <CommonDeleteBtn
                  action={() => deleteData('power.note')}
                  itemName="power note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}

        {/* Render Jerks */}
        {(nervousSystem?.jerks?.type || nervousSystem?.jerks?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Jerks: </Title>
              <Value>
                {nervousSystem.jerks.type}
                {/* Only render the status if type exists */}
                {nervousSystem.jerks.type && nervousSystem.jerks.status
                  ? ` - ${nervousSystem.jerks.status}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for type and status */}
            {nervousSystem.jerks.type && (
              <CommonDeleteBtn
                action={() => deleteData('jerks')}
                itemName="jerks"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.jerks.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.jerks.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('jerks.note')}
                  itemName="jerks note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Plantor Response */}
        {(nervousSystem?.plantorResponse?.status || nervousSystem?.plantorResponse?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Plantor Response: </Title>
              <Value>
                {nervousSystem.plantorResponse.status}
                {/* Only render the type if status exists */}
                {nervousSystem.plantorResponse.status && nervousSystem.plantorResponse.type
                  ? ` - ${nervousSystem.plantorResponse.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {nervousSystem.plantorResponse.status && (
              <CommonDeleteBtn
                action={() => deleteData('plantorResponse')}
                itemName="plantor response"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.plantorResponse.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.plantorResponse.note}</Value>
                </span>

                {/* Delete button for note (only one delete button when note exists) */}
                <CommonDeleteBtn
                  action={() => deleteData('plantorResponse.note')}
                  itemName="plantor response note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}

        {/* Render Cerebellar Function */}
        {(nervousSystem?.cerebellarFunction?.status || nervousSystem?.cerebellarFunction?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Cerebellar Function: </Title>
              <Value>
                {nervousSystem.cerebellarFunction.status}
                {/* Only render the type if status exists */}
                {nervousSystem.cerebellarFunction.status && nervousSystem.cerebellarFunction.type
                  ? ` - ${nervousSystem.cerebellarFunction.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {nervousSystem.cerebellarFunction.status && (
              <CommonDeleteBtn
                action={() => deleteData('cerebellarFunction')}
                itemName="cerebellar function"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.cerebellarFunction.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.cerebellarFunction.note}</Value>
                </span>

                {/* Delete button for note */}
                <CommonDeleteBtn
                  action={() => deleteData('cerebellarFunction.note')}
                  itemName="cerebellar function note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}

        {/* Render Sensory System */}
        {(nervousSystem?.sensorySystem?.status || nervousSystem?.sensorySystem?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Sensory System: </Title>
              <Value>
                {nervousSystem.sensorySystem.status}
                {/* Render type only if status exists */}
                {nervousSystem.sensorySystem.status && nervousSystem.sensorySystem.type
                  ? ` - ${nervousSystem.sensorySystem.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {nervousSystem.sensorySystem.status && (
              <CommonDeleteBtn
                action={() => deleteData('sensorySystem')}
                itemName="sensory system"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.sensorySystem.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.sensorySystem.note}</Value>
                </span>

                {/* Delete button for note */}
                <CommonDeleteBtn
                  action={() => deleteData('sensorySystem.note')}
                  itemName="sensory system note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Gait */}
        {(nervousSystem?.gaitFunction?.status || nervousSystem?.gaitFunction?.note) && (
          <li>
            <span className="text-left pr-5">
              <Title>Gait: </Title>
              <Value>
                {nervousSystem.gaitFunction.status}
                {/* Render type only if status exists */}
                {nervousSystem.gaitFunction.status && nervousSystem.gaitFunction.type
                  ? ` - ${nervousSystem.gaitFunction.type}`
                  : ''}
              </Value>
            </span>

            {/* Delete button for status and type */}
            {nervousSystem.gaitFunction.status && (
              <CommonDeleteBtn
                action={() => deleteData('gaitFunction')}
                itemName="gait function"
                isDelBtn={isDelBtn}
              />
            )}

            {/* Render Note on a new line */}
            {nervousSystem.gaitFunction.note && (
              <div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.gaitFunction.note}</Value>
                </span>

                {/* Delete button for note */}
                <CommonDeleteBtn
                  action={() => deleteData('gaitFunction.note')}
                  itemName="gait function note"
                  isDelBtn={isDelBtn}
                />
              </div>
            )}
          </li>
        )}


        {/* Render Neck Rigidity */}
        {nervousSystem?.neckRigidity?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Neck Rigidity: </Title>
              <Value>{nervousSystem.neckRigidity.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('neckRigidity.status')}
              itemName="neckRigidity.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Neck Rigidity with Note */}
        {nervousSystem?.neckRigidity?.status && nervousSystem?.neckRigidity?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.neckRigidity.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('neckRigidity.note')}
              itemName="neckRigidity.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.neckRigidity?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Neck Rigidity: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.neckRigidity.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('neckRigidity.note')}
                itemName="neckRigidity.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}


        {/* Render Kernig Sign */}
        {nervousSystem?.kernigSign?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Kernig Sign: </Title>
              <Value>{nervousSystem.kernigSign.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('kernigSign.status')}
              itemName="kernigSign.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Kernig Sign with Note */}
        {nervousSystem?.kernigSign?.status && nervousSystem?.kernigSign?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.kernigSign.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('kernigSign.note')}
              itemName="kernigSign.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.kernigSign?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Kernig Sign: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.kernigSign.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('kernigSign.note')}
                itemName="kernigSign.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}


        {/* Render Brudzinski Sign */}
        {nervousSystem?.brudzinskiSign?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Brudzinski Sign: </Title>
              <Value>{nervousSystem.brudzinskiSign.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('brudzinskiSign.status')}
              itemName="brudzinskiSign.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Brudzinski Sign with Note */}
        {nervousSystem?.brudzinskiSign?.status && nervousSystem?.brudzinskiSign?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.brudzinskiSign.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('brudzinskiSign.note')}
              itemName="brudzinskiSign.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.brudzinskiSign?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Brudzinski Sign: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.brudzinskiSign.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('brudzinskiSign.note')}
                itemName="brudzinskiSign.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}


        {/* Render JVP */}
        {nervousSystem?.jvp?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>JVP: </Title>
              <Value>{nervousSystem.jvp.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('jvp.status')}
              itemName="jvp.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render JVP with Note */}
        {nervousSystem?.jvp?.status && nervousSystem?.jvp?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.jvp.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('jvp.note')}
              itemName="jvp.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.jvp?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>JVP: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.jvp.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('jvp.note')}
                itemName="jvp.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Pulse */}
        {nervousSystem?.pulse?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Pulse: </Title>
              <Value>{nervousSystem.pulse.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('pulse.status')}
              itemName="pulse.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Pulse with Note */}
        {nervousSystem?.pulse?.status && nervousSystem?.pulse?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.pulse.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('pulse.note')}
              itemName="pulse.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.pulse?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Pulse: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.pulse.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('pulse.note')}
                itemName="pulse.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Murmur */}
        {nervousSystem?.murmur?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Murmur: </Title>
              <Value>{nervousSystem.murmur.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('murmur.status')}
              itemName="murmur.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Murmur with Note */}
        {nervousSystem?.murmur?.status && nervousSystem?.murmur?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.murmur.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('murmur.note')}
              itemName="murmur.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.murmur?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Murmur: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.murmur.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('murmur.note')}
                itemName="murmur.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}


        {/* Render Ascitis */}
        {nervousSystem?.ascitis?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Ascitis: </Title>
              <Value>{nervousSystem.ascitis.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('ascitis.status')}
              itemName="ascitis.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Ascitis with Note */}
        {nervousSystem?.ascitis?.status && nervousSystem?.ascitis?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.ascitis.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('ascitis.note')}
              itemName="ascitis.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.ascitis?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Ascitis: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.ascitis.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('ascitis.note')}
                itemName="ascitis.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Hepatomegaly */}
        {nervousSystem?.hepatomegaly?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Hepatomegaly: </Title>
              <Value>{nervousSystem.hepatomegaly.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('hepatomegaly.status')}
              itemName="hepatomegaly.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Hepatomegaly with Note */}
        {nervousSystem?.hepatomegaly?.status && nervousSystem?.hepatomegaly?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.hepatomegaly.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('hepatomegaly.note')}
              itemName="hepatomegaly.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.hepatomegaly?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Hepatomegaly: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.hepatomegaly.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('hepatomegaly.note')}
                itemName="hepatomegaly.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Splenomegaly */}
        {nervousSystem?.splenomegaly?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Splenomegaly: </Title>
              <Value>{nervousSystem.splenomegaly.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('splenomegaly.status')}
              itemName="splenomegaly.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Splenomegaly with Note */}
        {nervousSystem?.splenomegaly?.status && nervousSystem?.splenomegaly?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.splenomegaly.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('splenomegaly.note')}
              itemName="splenomegaly.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.splenomegaly?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Splenomegaly: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.splenomegaly.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('splenomegaly.note')}
                itemName="splenomegaly.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}


        {/* Render RR */}
        {nervousSystem?.rr?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>RR: </Title>
              <Value>{nervousSystem.rr.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('rr.status')}
              itemName="rr.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render RR with Note */}
        {nervousSystem?.rr?.status && nervousSystem?.rr?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.rr.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('rr.note')}
              itemName="rr.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.rr?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>RR: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.rr.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('rr.note')}
                itemName="rr.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Rhanchi */}
        {nervousSystem?.rhanchi?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Rhanchi: </Title>
              <Value>{nervousSystem.rhanchi.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('rhanchi.status')}
              itemName="rhanchi.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Rhanchi with Note */}
        {nervousSystem?.rhanchi?.status && nervousSystem?.rhanchi?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.rhanchi.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('rhanchi.note')}
              itemName="rhanchi.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.rhanchi?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Rhanchi: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.rhanchi.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('rhanchi.note')}
                itemName="rhanchi.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Creps */}
        {nervousSystem?.creps?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Creps: </Title>
              <Value>{nervousSystem.creps.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('creps.status')}
              itemName="creps.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Creps with Note */}
        {nervousSystem?.creps?.status && nervousSystem?.creps?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.creps.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('creps.note')}
              itemName="creps.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.creps?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Creps: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.creps.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('creps.note')}
                itemName="creps.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

        {/* Render Breath Sound */}
        {nervousSystem?.breathSound?.status && (
          <li>
            <span className="text-left pr-5">
              <Title>Breath Sound: </Title>
              <Value>{nervousSystem.breathSound.status}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('breathSound.status')}
              itemName="breathSound.status"
              isDelBtn={isDelBtn}
            />
          </li>
        )}

        {/* Render Breath Sound with Note */}
        {nervousSystem?.breathSound?.status && nervousSystem?.breathSound?.note ? (
          <div>
            <span className="text-left pl-2 pr-5">
              <Value>- {nervousSystem.breathSound.note}</Value>
            </span>
            <CommonDeleteBtn
              action={() => deleteData('breathSound.note')}
              itemName="breathSound.note"
              isDelBtn={isDelBtn}
            />
          </div>
        ) : (
          nervousSystem?.breathSound?.note && (
            <li>
              <span className="text-left">
                <div>
                  <Title>Breath Sound: </Title>
                </div>
                <span className="text-left pl-2">
                  <Value>- {nervousSystem.breathSound.note}</Value>
                </span>
              </span>
              <CommonDeleteBtn
                action={() => deleteData('breathSound.note')}
                itemName="breathSound.note"
                isDelBtn={isDelBtn}
              />
            </li>
          )
        )}

      </ul>
    </div>
  );
};

export default NervousSystem;
