import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { handleGroupData } from '../../../utils/helpers';
import ChiefComplainGroupForm from '../modals/chiefComplainGroupForm';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import SearchArea from '../partials/searchAreaAsync';
import TabNav from '../partials/tabNav';
import SearchItemSuggestions from '../searchItemSuggestions';

const ChiefComplainModal = ({
  modalTitle,
  selectedChiefComplains,
  setSelectedChiefComplains,
  selectedComplainGroups,
  setSelectedComplainGroups,
  isComplainModal,
  setIsComplainModal,
}) => {
  const [chiefComplains, setChiefComplains] = useState([]);
  const [chiefComplainsInSearch, setChiefComplainsInSearch] = useState([]);
  const [complainGroups, setComplainGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const perPage = 25;

  //============for search suggestions ======================//
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const getChipComplainGroups = async () => {
    try {
      const data = await getRequest('chief-complains/groups');
      console.log(data, 'data');
      setComplainGroups(data);
    } catch (error) {
      console.error(error);
    }
  };

  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = chiefComplains?.find(
      (chiefItem) =>
        chiefItem.name?.trim()?.toLowerCase() === itemName.trim().toLowerCase(),
    );

    if (
      selectedChiefComplains?.some(
        (data) => data.name.trim() === item.name.trim(),
      )
    ) {
      setSelectedChiefComplains(
        selectedChiefComplains.map((complain) => {
          if (complain.name !== item.name) return complain;
          else return { ...complain, note: item?.note };
        }),
      );
    } else {
      console.log(item, note, 'item');
      setSelectedChiefComplains([
        ...selectedChiefComplains,
        { name: item.name, duration: '', unit: 'day(s)', note: note },
      ]);
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  const { isLoading: isChipComplainLoading, refetch: getChipComplains } =
    useGetRequest(
      'getChipComplain',
      `chief-complains?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setChiefComplains([...chiefComplains, ...data.data]);
        } else {
          setChiefComplains(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectChiefComplain = (item) => {
    const isItemExist = selectedChiefComplains?.some(
      (complain) => complain.name === item.name,
    );
    const updatedComplains = isItemExist
      ? selectedChiefComplains?.filter(
          (complain) => complain.name !== item.name,
        )
      : [
          ...selectedChiefComplains,
          { name: item.name, side: '', duration: '', unit: 'day(s)', note: '' },
        ];
    setSelectedChiefComplains(updatedComplains);
  };

  const deleteChiefComplain = async (complainId) => {
    await deleteRequest(`chief-complains/${complainId}`)
      .then((data) => {
        setChiefComplains(
          chiefComplains?.filter((complain) => complain.id !== complainId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteChiefComplainGroup = async (groupId) => {
    await deleteRequest(`chief-complains/groups/${groupId}`)
      .then((data) => {
        setComplainGroups(
          complainGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectComplainGroup = (group) => {
    handleGroupData(
      group,
      selectedComplainGroups,
      setSelectedComplainGroups,
      selectedChiefComplains,
      setSelectedChiefComplains,
      'Chief complain group',
    );
  };

  const removeChiefComplain = (item) => {
    setSelectedChiefComplains(
      selectedChiefComplains?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleExtra = (item, fieldName, val) => {
    const objIndex = selectedChiefComplains.findIndex(
      (complain) => complain.name == item.name,
    );
    selectedChiefComplains[objIndex][fieldName] = val;
    setSelectedChiefComplains([...selectedChiefComplains]);
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getChipComplainSuggestions',
      `prescriptions/suggestions?suggestion_type=chief_complain`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const selectChiefComplainWithNote = (item) => {
    let data = [...selectedChiefComplains];
    const isItemExist = data.some((complain) => {
      if (complain.name === item.name) {
        return true;
      }
    });

    let updatedComplains = [];
    if (isItemExist) {
      updatedComplains = data.map((complain) => {
        if (complain.name !== item.name) return complain;
        else return { ...complain, note: item?.note };
      });
    } else {
      updatedComplains = [
        ...data,
        {
          name: item.name,
          duration: '',
          unit: 'day(s)',
          note: item?.note ? item.note : '',
        },
      ];
    }
    setSelectedChiefComplains([...updatedComplains]);
  };

  useEffect(() => {
    getChipComplainGroups();
  }, []);

  useEffect(() => {
    getChipComplains();
  }, [currentPage]);

  useEffect(() => {
    if (isComplainModal) {
      getSuggestions();
    } else {
      setSearchQuery('');
      setChiefComplainsInSearch([]);
    }
  }, [isComplainModal]);

  const selectedList = () => {
    return selectedChiefComplains?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col className="name" md="3" sm="12" xs="12">
            {item.name} <span className="pl-2">for</span>
          </Col>
          <Col md="6" sm="12" xs="12">
            <Row>
              <Col md="4" sm="3" xs="3" className="responsive pl-0 pr-0">
                <Form.Select
                  className="form-control form-control-sm"
                  defaultValue={item?.side}
                  onChange={(e) =>
                    handleExtra(item, 'side', e.target.value)
                  }
                >
                  <option value="">Side</option>
                  <option value={'rt'}>RT</option>
                  <option value={'lt'}>LT</option>
                  <option value={'bil'}>BIL</option>
                </Form.Select>
              </Col>
              <Col
                md="3"
                sm="6"
                xs="6"
                className="pr-0 responsive"
              >
                <Form.Control
                  size="sm"
                  min={0}
                  type="number"
                  placeholder="duration"
                  defaultValue={item?.duration}
                  onChange={(e) =>
                    handleExtra(item, 'duration', e.target.value)
                  }
                />
              </Col>
              <Col md="5" sm="6" xs="6" className="responsive pr-0">
                <Form.Select
                  className="form-control form-control-sm"
                  defaultValue={item.unit}
                  onChange={(e) =>
                    handleExtra(item, 'unit', e.target.value)
                  }
                >
                  <option value={'hour(s)'}>Hour(s)</option>
                  <option value={'day(s)'}>Day(s)</option>
                  <option value={'week(s)'}>Week(s)</option>
                  <option value={'month(s)'}>Month(s)</option>
                  <option value={'year(s)'}>Year(s)</option>
                  <option value={'long time'}>Long Time</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
          <Col md="3" sm="12" xs="12" className="responsive">
            <Row>
              <Col md="10" xs="10" className="pr-0">
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Enter note"
                  defaultValue={item?.note}
                  onChange={(e) =>
                    handleExtra(item, 'note', e.target.value)
                  }
                />
              </Col>
              <Col lg="1" md="1" sm="1" xs="2">
                <i
                  className="fa fa-times-circle pt-2 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => removeChiefComplain(item)}
                ></i>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    });
  };

  let allChiefComplains = chiefComplains?.map((item, index) => {
    let isSelected = selectedChiefComplains.some(
      (data) => data.name === item.name,
    );
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectChiefComplain}
        removeClickAction={deleteChiefComplain}
        suggestions={suggestions}
        selectItemWithNote={selectChiefComplainWithNote}
      />
    );
  });

  let groupWithMinusBtn = complainGroups.map((group, index) => {
    let isSelected = selectedComplainGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectComplainGroup}
        removeClickAction={deleteChiefComplainGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('chief-complains', {
          name: selectedData,
        });
        setChiefComplains([...chiefComplains, data]);
        setSearchQuery('');
        setSelectedChiefComplains([
          ...selectedChiefComplains,
          {
            name: selectedData,
            side: '',
            duration: '',
            unit: 'day(s)',
            note: '',
          },
        ]);
      } else {
        const isExisting = selectedChiefComplains?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );
        setSelectedChiefComplains(
          isExisting
            ? selectedChiefComplains?.filter(
                ({ name }) =>
                  name?.toLowerCase() !== selectedData?.toLowerCase(),
              )
            : [
                ...selectedChiefComplains,
                {
                  name: selectedData,
                  side: '',
                  duration: '',
                  unit: 'day(s)',
                  note: '',
                },
              ],
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = async (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setChiefComplainsInSearch([]);
      const url = `chief-complains?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      await getRequest(url)
        .then((data) => {
          setChiefComplainsInSearch(data?.data);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsComplainModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  return (
    <Modal show={isComplainModal} size="lg" className="customize-modal-size">
      {/* <ModalHeader title={modalTitle} action={setIsComplainModal} /> */}
      <Modal.Body ref={ref}>
        <SearchArea
          modalTitle={modalTitle}
          closeAction={setIsComplainModal}
          setSearchOptions={setChiefComplainsInSearch}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={chiefComplainsInSearch}
          selectedInputs={selectedChiefComplains}
          placeholder={'chief complain'}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        {/* <TestSelect /> */}
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedChiefComplains}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allChiefComplains}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <ChiefComplainGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedChiefComplains.map((item) => item.name)}
          complainGroups={complainGroups}
          setComplainGroups={setComplainGroups}
          selectedComplainGroups={selectedComplainGroups}
          setSelectedComplainGroups={setSelectedComplainGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">
          {/* <SelectedComplainList
            selectedChiefComplains={selectedChiefComplains}
            handleExtra={handleExtra}
            removeChiefComplain={removeChiefComplain}
          /> */}
          {/* {selectedChiefComplains?.map((item, index) => (
            <SelectedComplain
              key={index}
              item={item}
              index={index}
              handleExtra={handleExtra}
              removeChiefComplain={removeChiefComplain}
              inputRefs={inputRefs}
              handleFocus={handleFocus}
              selectedIndexForFocus={selectedIndexForFocus}
            />
          ))} */}
          {selectedList()}
        </div>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(ChiefComplainModal);
