import React, { useEffect } from 'react';
import Header from '../../components/doctors/partials/Header';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import TopLeftMenu from '../../components/doctors/partials/topLeftMenu';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetRequest } from '../../hooks/useGetRequest';

import {
  userRole,
  getAvatarName,
  getColors,
  deleteConfirmation,
  capitalizeFirstLetter,
  agePrint,
  separateDateTime,
} from '../../utils/helpers';
import { deleteRequest, getRequest } from '../../utils/axiosRequests';
import loadingImg from '../../img/loading.gif';
import pencilIcon from '../../img/pencil.png';
import ImageItem from '../../components/doctors/partials/ImageItem';
import moment from 'moment';
import { toast } from 'react-toastify';
import { usePostRequest } from '../../hooks/usePostRequest';
import { countCharacters, countMessages } from '../../utils/helpers';
import CustomDateRangePicker from '../../components/doctors/customDateRangePicker';

function SendSMS() {
  //const currentDate = moment().format('YYYY-MM-DD');
  const [smsSummary, setSmsSumary] = useState({});
  const [patients, setPatients] = useState(0);
  const [validPatients, setValidPatients] = useState([]);
  const [validPhoneNumbers, setValidPhoneNumbers] = useState(0);
  const [totalSms, setTotalSms] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const userInfo = useSelector((state) => state.authReducer.data);
  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );
  const [q, setQ] = useState('');
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [topics, setTopics] = useState('');
  const [topicItems, setTopicItems] = useState([]);
  const [selectedTopicItem, setSelectedTopicItem] = useState('');

  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};

  const handleNewMessage = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const getTopicItems = async (value) => {
    await getRequest(
      `prescriptions/topic-items?topics=${value}&${
        userInfo.userType === 'assistant' ? 'doctorId=' + activeDoctor.id : ''
      }`,
    ).then((data) => {
      if (data?.length > 0) {
        setTopicItems(data[0].names);
      }
    });
  };

  const { isLoading: isSummaryLoading, refetch: getSummary } = useGetRequest(
    'sms-summary',
    `sms/sms-summary${
      userInfo.userType === 'assistant' ? '?doctorId=' + activeDoctor.id : ''
    }`,
    (data) => {
      console.log('sms-summary: ', data);
      setSmsSumary(data);
    },
    (e) => {
      console.log(e);
    },
  );

  useEffect(() => {
    getSummary();
  }, []);

  const getPatients = () => {
    setIsDataLoading(true);
    let url = `patients/patients-list-for-sms?${
      userInfo.userType === 'assistant'
        ? 'doctorId=' + activeDoctor.id + '&'
        : ''
    }q=all`;
    if (
      topics === 'name' ||
      topics === 'patientId' ||
      topics === 'phoneNumber'
    ) {
      url = `patients/patients-list-for-sms?${
        textValue ? '&q=' + textValue : ''
      }`;
    } else if (
      topics === 'chiefComplains' ||
      topics === 'diagnoses' ||
      topics === 'investigations' ||
      topics === 'observations' ||
      topics === 'advices'
    ) {
      url = `patients/patients-list-for-sms?topics=${topics}&q=${textValue}&${
        userInfo.userType === 'assistant' ? 'doctorId=' + activeDoctor.id : ''
      }`;
    }
    if (topics === 'appointmentDate' && startDate && endDate) {
      url = `appointments/patients-list-for-sms?${
        userInfo.userType === 'assistant'
          ? 'doctorId=' + activeDoctor.id + '&'
          : ''
      }startDate=${startDate}&endDate=${endDate}`;
    }
    getRequest(url)
      .then((data) => {
        console.log(data);
        setIsDataLoading(false);
        setPatients(data.totalPatient);
        setValidPhoneNumbers(data.validPhoneNumbers);
        setTotalSms(0);
        setTotalCost(0);
        setSellPrice(data.sellPrice);
        setValidPatients(data?.validPatients);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.log(e.message);
      })
      .finally(() => {
        // setIsDataLoading(true);
        // setIsPatientLoading(true);
      });
  };

  // const handleFilter = (e) => {
  //   e.preventDefault();
  //   setPatients(0);
  //   setTotalSms(0);
  //   getPatients(q);
  // };

  // const handleClear = () => {
  //   setQ('');
  //   setCurrentPage(1);
  //   getPatients('clear');
  // };

  const replaceId = (obj) => {
    if (Array.isArray(obj)) {
      return obj?.map(replaceId);
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (key === '_id') {
          newObj['id'] = obj[key];
        } else {
          newObj[key] = replaceId(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };

  // const validation = useValidation;
  const onSuccess = (data) => {
    if (data.success) {
      toast.success('SMS sent Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
      getSummary();
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setMessageContent('');
  };
  const onError = (error) => {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setMessageContent('');
  };

  useState(() => {
    console.log('topics: ', topics);
  }, [topics]);

  const { isSendingSms, mutate: submitSMSinfo } = usePostRequest(
    'sms/send-sms-by-doctor',
    {
      toUsers: validPhoneNumbers,
      messageContent,
      toUserIds: validPatients?.map((user) => user?.id),
      ...(userInfo.userType === 'assistant' && { doctorId: activeDoctor.id }),
    },
    onSuccess,
    onError,
  );

  const handleChangeTopics = (e) => {
    setTextValue('');
    setTopics(e.target.value);
    if (
      e.target.value === 'chiefComplains' ||
      e.target.value === 'diagnoses' ||
      e.target.value === 'observations' ||
      e.target.value === 'investigations' ||
      e.target.value === 'advices'
    ) {
      getTopicItems(e.target.value);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            <TopLeftMenu />
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Text Message
                </h6>
              </div>
              <div className="card-body appointment-card-body">
                <Row
                  className="sms-block-container"
                  style={{
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  }}
                >
                  <Col md={4}>
                    <div className="statistics-block-sms">
                      <Row>
                        <Col md={4} className="center">
                          <div
                            className="sms-type-circle center"
                            style={{ backgroundColor: '#0086ca' }}
                          >
                            {' '}
                            <i className="fa fa-mail-bulk fa-2x icon"></i>
                          </div>
                        </Col>
                        <Col md={8}>
                          <span className="title">Total Text Message</span>
                          <span
                            style={{
                              fontSize: '2rem',
                              fontWeight: 'bold',
                              color: '#0086ca',
                            }}
                          >
                            {smsSummary && smsSummary?.totalCustomerSms
                              ? smsSummary.totalCustomerSms
                              : 0}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="statistics-block-sms">
                      <Row>
                        <Col md={4} className="center">
                          <div
                            className="sms-type-circle center"
                            style={{ backgroundColor: '#f7c434' }}
                          >
                            {' '}
                            <i className="fa fa-envelope-open-text fa-2x icon"></i>
                          </div>
                        </Col>
                        <Col md={8}>
                          <span className="title">Total Used</span>
                          <span
                            style={{
                              fontSize: '2rem',
                              fontWeight: 'bold',
                              color: '#eb362b',
                            }}
                          >
                            {smsSummary && smsSummary?.totalCustomerSms
                              ? smsSummary.totalCustomerSms -
                                smsSummary.totalRemainingCustomerSms
                              : 0}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="statistics-block-sms">
                      <Row>
                        <Col md={4} className="center">
                          <div
                            className="sms-type-circle center"
                            style={{ backgroundColor: '#358c7d' }}
                          >
                            {' '}
                            <i className="fa fa-envelope fa-2x icon"></i>
                          </div>
                        </Col>
                        <Col md={8}>
                          <span className="title">Total Remaining</span>
                          <span
                            style={{
                              fontSize: '2rem',
                              fontWeight: 'bold',
                              color: '#358c7d',
                            }}
                          >
                            {smsSummary && smsSummary?.totalCustomerSms
                              ? smsSummary.totalRemainingCustomerSms
                              : 0}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row style={{ margin: '3rem 0rem' }}>
                  <Col style={{ marginTop: '3rem' }} md={4}>
                    <Form.Select
                      aria-label="Default select example"
                      className="sms-patient-select"
                      onChange={(e) => handleChangeTopics(e)}
                    >
                      <option>Select</option>
                      <option value="">All Patient</option>
                      <option value="name">Name</option>
                      <option value="patientId">PatientId</option>
                      <option value="phoneNumber">Phone Number</option>
                      <option value="appointmentDate">Appointment Date</option>
                      <option value="chiefComplains">Chief Complain</option>
                      <option value="diagnoses">Diagnosis</option>
                      <option value="investigations">investigations</option>
                      <option value="observations">Observations</option>
                      <option value="advices">Advice</option>
                    </Form.Select>

                    {(topics === 'chiefComplains' ||
                      topics === 'diagnoses' ||
                      topics === 'investigations' ||
                      topics === 'observations' ||
                      topics === 'advices') && (
                      <Form.Select
                        aria-label="Select criteria"
                        className="sms-patient-select"
                        onChange={(e) => setTextValue(e.target.value)}
                      >
                        <option>Select</option>
                        {topicItems?.map((item, index) => (
                          <option key={`${index}-${item}`} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                    {(topics === 'name' ||
                      topics === 'patientId' ||
                      topics === 'phoneNumber') && (
                      <input
                        aria-label="Text input"
                        className="sms-patient-select"
                        type="text"
                        value={textValue}
                        onChange={(e) => setTextValue(e.target.value)}
                      />
                    )}
                    {topics === 'appointmentDate' && (
                      <CustomDateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        styles={{ width: '100%' }}
                      />
                    )}

                    <Button
                      variant="warning"
                      type="submit"
                      className="btn"
                      onClick={(e) => {
                        getPatients();
                      }}
                      style={{
                        display: 'block',
                        marginTop: '1rem',
                        color: 'black',
                        width: '100%',
                      }}
                    >
                      <i className="fa fa-filter"></i> Search Patient
                    </Button>
                  </Col>
                  <Col md={2}>
                    <div className="sms-patient-summary">
                      <h5>Message Information</h5>
                      <p>
                        <span
                          style={{ width: '10rem', display: 'inline-block' }}
                        >
                          Total Patient{' '}
                        </span>
                        : {patients}
                      </p>
                      <p>
                        <span
                          style={{ width: '10rem', display: 'inline-block' }}
                        >
                          Valid Phone Number
                        </span>
                        : {validPatients?.length}
                      </p>
                      <p>
                        <span
                          style={{ width: '10rem', display: 'inline-block' }}
                        >
                          Total SMS
                        </span>
                        :{' '}
                        {countMessages(messageContent) > 0 &&
                        validPhoneNumbers?.length
                          ? validPhoneNumbers?.length *
                            countMessages(messageContent)
                          : countMessages(messageContent) > 0
                          ? countMessages(messageContent)
                          : 0}
                      </p>
                      <p>
                        <span
                          style={{ width: '10rem', display: 'inline-block' }}
                        >
                          Total Cost
                        </span>
                        :{' '}
                        {countMessages(messageContent) > 0
                          ? validPhoneNumbers?.length *
                            countMessages(messageContent) *
                            sellPrice
                          : 0}{' '}
                        Tk
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        {/* <Form.Label>Example textarea</Form.Label> */}
                        <Form.Control
                          as="textarea"
                          rows={9}
                          placeholder="Write a message"
                          className="mt-5"
                          style={{ border: '1px solid lightgrey' }}
                          value={messageContent}
                          onChange={(e) => setMessageContent(e.target.value)}
                        />
                        <div style={{ textAlign: 'right' }}>
                          {messageContent
                            ? `${countCharacters(
                                messageContent,
                              )}/${countMessages(messageContent)}`
                            : '153/0'}
                        </div>
                      </Form.Group>

                      <Button
                        type="button"
                        className="btn btn-primary mt-2 mb-3"
                        disabled={
                          !countMessages(messageContent) ||
                          !validPhoneNumbers?.length ||
                          isSendingSms
                        }
                        onClick={submitSMSinfo}
                      >
                        {isSendingSms ? 'Sending...' : 'Send Message'}
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default SendSMS;
