import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.authReducer.data);
  const location = useLocation();
  // const protectedRoute = false;
  // console.log('before check');
  if (!user || !user.token) {
    return <Navigate to="/signin" replace />;
  }

  // if (user?.userType === 'assistant' && location.pathname !== '/send-message') {
  //   return <Navigate to="/send-message" replace />;
  // } else
  if (
    user?.userType === 'assistant' &&
    location.pathname !== '/appointments' &&
    location.pathname !== '/new-appointment' &&
    location.pathname !== '/edit-appointment' &&
    location.pathname !== '/send-sms'
  ) {
    return <Navigate to="/appointments" replace />;
  }

  return children;
};

export default ProtectedRoute;
