import { memo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const EarNoseThrothExaminationNew = ({
  earNoseThroths,
  handleOnExaminationData,
}) => {
  const weekButtonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [isSfh, setIsSfh] = useState(false);
  const [isWeekButton, setIsWeekButton] = useState(false);
  const [isUterusWeekButton, setIsUterusWeekButton] = useState(false);

  let selectedData = earNoseThroths || {
    larynx: {
      supra_glottic_rt: false,
      supra_glottic_lt: false,
      glottic_rt: false,
      glottic_lt: false,
      subglottic: false,
      transglottic: false,
      note: '',
    },
    hypopharynx: {
      pyriform_fossa: false,
      post_cricoid: false,
      posterior_wall: false,
      note: '',
    },
    tongue: {
      lateral_border: false,
      dorsum: false,
      tip: false,
      ventral_surface: false,
      note: '',
    },

    oral_cavity: {
      lip_floor_of_mouth: false,
      buccal: false,
      retromollar_trigon: false,
      maxillary_alveolus_and_hard_palate: false,
      mandibular_alveolus: false,
      note: '',
    },
    oropharynx: {
      base_of_tongue: false,
      soft_palate: false,
      lateral_wall: false,
      posterior_wall: false,
      note: '',
    },
    nasopharynx: '',

    sinonasal: {
      nasal_cavity: false,
      maxillary_sinus: false,
      ethmoid_sinus: false,
      frontal_sinus: false,
      sphenoid_sinus: false,
      all: false,
      note: '',
    },
    thyroid: {
      right: false,
      left: false,
      both: false,
      note: '',
    },
    salivary_gland: {
      parotid_rt: false,
      parotid_lt: false,
      submandibular_rt: false,
      submandibular_lt: false,
      sublingual: false,
      minor_salivary_gland: false,
      note: '',
    },
    skin: {
      scalp: false,
      neck: false,
      face: false,
      note: '',
    },
    site:{
      rt: false,
      lt: false,
      both: false,
      central: false,
      note: '',
    },
    size:{
      three_four: false,
      four_three: false,
      five_two: false,
      note: '',
    },
    conssistency:{
      firm: false,
      hard: false,
      soft: false,
      note: '',
    },
    structure:{
      free: false,
      fixed: false,
      mobile: false,
      immobile: false,
      note: '',
    },
    lymphoma: '',
    level:{
      ia: false,
      ib: false,
      ii: false,
      iii: false,
      iv: false,
      va: false,
      vb: false,
      note: '',
    },
    fm: '',
    fhsPresent: false,
    fhsAbsent: false,
    presentation: '',
    nad: false,
    uterus: '',
    wkSize: '',
    os: '',
    cervix: '',
    effacement: '',
    station: '',
    mumbrance: '',
    showPresent: false,
    showAbsent: false,
    inspectionNad: false,
    lump: '',
    pseCervix: '',
    bmeUterus: '',
    bmeWkSize: '',
    fornix: '',
    cmtPresent: false,
    cmtAbsent: false,
    bleedingAbsent: false,
    bleedingPresent: false,
  };

  console.log(selectedData, 'earNoseThroths123');

  const clearData = (itemName) => {
    switch (itemName) {
      case 'larynx':
        earNoseThroths.supra_glottic_rt = false;
        earNoseThroths.supra_glottic_lt = false;
        earNoseThroths.glottic_rt = false;
        earNoseThroths.glottic_lt = false;
        earNoseThroths.subglottic = false;
        earNoseThroths.transglottic = false;
        break;
      case 'hypopharynx':
        earNoseThroths.hypopharynx.pyriform_fossa = false;
        earNoseThroths.hypopharynx.post_cricoid = false;
        earNoseThroths.hypopharynx.posterior_wall = false;
        break;
      case 'tongue':
        earNoseThroths.tongue.lateral_border = false;
        earNoseThroths.tongue.dorsum = false;
        earNoseThroths.tongue.tip = false;
        earNoseThroths.tongue.ventral_surface = false;
        break;
      case 'oral_cavity':
        earNoseThroths.oral_cavity.lip_floor_of_mouth = false;
        earNoseThroths.oral_cavity.buccal = false;
        earNoseThroths.oral_cavity.retromollar_trigon = false;
        earNoseThroths.oral_cavity.maxillary_alveolus_and_hard_palate = false;
        earNoseThroths.oral_cavity.mandibular_alveolus = false;
        break;
      case 'oropharynx':
        earNoseThroths.oropharynx.base_of_tongue = false;
        earNoseThroths.oropharynx.lateral_wall = false;
        earNoseThroths.oropharynx.soft_palate = false;
        earNoseThroths.oropharynx.posterior_wall = false;
        break;

      case 'sinonasal':
        earNoseThroths.sinonasal.nasal_cavity = false;
        earNoseThroths.sinonasal.maxillary_sinus = false;
        earNoseThroths.sinonasal.ethmoid_sinus = false;
        earNoseThroths.sinonasal.frontal_sinus = false;
        earNoseThroths.sinonasal.sphenoid_sinus = false;
        earNoseThroths.sinonasal.all = false;
        break;
      case 'thyroid':
        earNoseThroths.thyroid.right = false;
        earNoseThroths.thyroid.left = false;
        earNoseThroths.thyroid.both = false;
        break;
      case 'salivary_gland':
        earNoseThroths.salivary_gland.parotid_rt = false;
        earNoseThroths.salivary_gland.parotid_lt = false;
        earNoseThroths.salivary_gland.submandibular_rt = false;
        earNoseThroths.salivary_gland.submandibular_lt = false;
        earNoseThroths.salivary_gland.sublingual = false;
        earNoseThroths.salivary_gland.minor_salivary_gland = false;
        break;
      case 'skin':
        earNoseThroths.skin.right = false;
        earNoseThroths.skin.left = false;
        earNoseThroths.skin.both = false;
        break;
      case 'site':
        earNoseThroths.site.rt = false;
        earNoseThroths.site.lt = false;
        earNoseThroths.site.both = false;
        earNoseThroths.site.central = false;
        break;
      case 'size':
        earNoseThroths.size.three_four = false;
        earNoseThroths.size.four_three = false;
        earNoseThroths.size.five_two = false;
        break;
      case 'consistency':
        earNoseThroths.conssistency.firm = false;
        earNoseThroths.conssistency.hard = false;
        earNoseThroths.conssistency.soft = false;
        break;
      case 'structure':
        earNoseThroths.structure.free = false;
        earNoseThroths.structure.fixed = false;
        earNoseThroths.structure.immobile = false;
        earNoseThroths.structure.mobile = false;
        break;
      case 'level':
        earNoseThroths.level.ia = false;
        earNoseThroths.level.ib = false;
        earNoseThroths.level.ii = false;
        earNoseThroths.level.iii = false;
        earNoseThroths.level.iv = false;
        earNoseThroths.level.va = false;
        earNoseThroths.level.vb = false;
        break;
      case 'fm':
        earNoseThroths.fm = '';
        break;
      case 'fhs':
        earNoseThroths.fhsPresent = false;
        earNoseThroths.fhsAbsent = false;
        break;
      case 'show':
        selectedData['showPresent'] = false;
        selectedData['showAbsent'] = false;
        break;
      case 'cmt':
        selectedData['cmtPresent'] = false;
        selectedData['cmtAbsent'] = false;
        break;
      case 'bleeding':
        selectedData['bleedingAbsent'] = false;
        selectedData['bleedingPresent'] = false;
        break;
      default:
        break;
    }
    handleOnExaminationData(earNoseThroths, 'earNoseThroths');
  };

  const handleData = (rowName, fieldName, val) => {
    setIsSfh(false);
    setIsUterusWeekButton(false);
    setIsWeekButton(false);

    switch (rowName) {
      case 'larynx':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            supra_glottic_rt: false,
            supra_glottic_lt: false,
            glottic_rt: false,
            glottic_lt: false,
            subglottic: false,
            transglottic: false,
            note: selectedData?.larynx?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'hypopharynx':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            pyriform_fossa: false,
            post_cricoid: false,
            posterior_wall: false,
            note: selectedData?.hypopharynx?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;

      case 'tongue':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            lateral_border: false,
            dorsum: false,
            tip: false,
            ventral_surface: false,
            note: selectedData?.tongue?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'oral_cavity':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            lip_floor_of_mouth: false,
            buccal: false,
            retromollar_trigon: false,
            maxillary_alveolus_and_hard_palate: false,
            mandibular_alveolus: false,
            note: selectedData?.oral_cavity?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'oropharynx':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            base_of_tongue: false,
            soft_palate: false,
            lateral_wall: false,
            posterior_wall: false,
            note: selectedData?.oropharynx?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'sinonasal':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            nasal_cavity: false,
            maxillary_sinus: false,
            ethmoid_sinus: false,
            frontal_sinus: false,
            sphenoid_sinus: false,
            all: false,
            note: selectedData?.sinonasal?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'thyroid':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            right: false,
            left: false,
            both: false,
            note: selectedData?.thyroid?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'salivary_gland':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            parotid_rt: false,
            parotid_lt: false,
            submandibular_rt: false,
            submandibular_lt: false,
            sublingual: false,
            minor_salivary_gland: false,
            note: selectedData?.salivary_gland?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'skin':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            scalp: false,
            neck: false,
            face: false,
            note: selectedData?.skin?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'site':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            rt: false,
            lt: false,
            both: false,
            central: false,
            note: selectedData?.site?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'size':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            three_four: false,
            four_three: false,
            five_two: false,
            note: selectedData?.size?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'consistency':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            firm: false,
            hard: false,
            soft: false,
            note: selectedData?.consistency?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'structure':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            free: false,
            fixed: false,
            mobile: false,
            immobile: false,
            note: selectedData?.structure?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'level':
        if (fieldName !== 'note') {
          selectedData[rowName] = {
            ia: false,
            ib: false,
            ii: false,
            iii: false,
            iv: false,
            va: false,
            vb: false,
            note: selectedData?.level?.note,
          };
        }
        selectedData[rowName][fieldName] = val;
        break;
      case 'nasopharynx':
      case 'lymphoma':
        selectedData[rowName] = val;
        break;
      case 'fhsPresent':
        selectedData['fhsAbsent'] = false;
        selectedData[rowName] = val;
        break;
      case 'fhsAbsent':
        selectedData['fhsPresent'] = false;
        selectedData[rowName] = val;
        break;
      case 'showPresent':
        selectedData['showAbsent'] = false;
        selectedData[rowName] = val;
        break;
      case 'showAbsent':
        selectedData['showPresent'] = false;
        selectedData[rowName] = val;
        break;
      case 'cmtPresent':
        selectedData['cmtAbsent'] = false;
        selectedData[rowName] = val;
        break;
      case 'cmtAbsent':
        selectedData['cmtPresent'] = false;
        selectedData[rowName] = val;
        break;
      default:
        selectedData[rowName] = val;
    }
    handleOnExaminationData(selectedData, 'earNoseThroths');
  };

  return (
    <div className="gyne-examination">
      <div className="title-gyne-examination">
        Site of primary lesion with subsite:
      </div>
      <hr className="hr" />

      <Form.Group as={Row} className="pb-2">
        {/* Label */}
        <Col md={2} className="d-flex align-items-top">
          <Form.Label>Larynx:</Form.Label>
        </Col>

        {/* Radio Buttons and Text Field */}
        <Col md={10}>
          <Row>
            {/* First Two Radio Buttons */}
            <Col md={4}>
              <Form.Check
                type={`radio`}
                id={`supra_glottic_rt`}
                label={`Supra glottic (Rt)`}
                checked={selectedData?.larynx?.supra_glottic_rt === true}
                onChange={(e) =>
                  handleData('larynx', 'supra_glottic_rt', e.target.checked)
                }
              />
            </Col>
            <Col md={4}>
              <Form.Check
                type={`radio`}
                id={`supra_glottic_lt`}
                label={`Supra glottic (Lt)`}
                checked={selectedData?.larynx?.supra_glottic_lt === true}
                onChange={(e) =>
                  handleData('larynx', 'supra_glottic_lt', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.larynx?.note}
                onChange={(e) => handleData('larynx', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            {/* Second Two Radio Buttons - Now left-aligned */}
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`glottic_rt`}
                label={`Glottic (Rt)`}
                checked={selectedData?.larynx?.glottic_rt === true}
                onChange={(e) =>
                  handleData('larynx', 'glottic_rt', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`glotic_lt`}
                label={`Glotic (Lt)`}
                checked={selectedData?.larynx?.glottic_lt === true}
                onChange={(e) =>
                  handleData('larynx', 'glottic_lt', e.target.checked)
                }
              />
            </Col>
          </Row>

          <Row className="mt-2">
            {/* Second Two Radio Buttons - Now left-aligned */}
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`subglottic`}
                label={`Subglottic`}
                checked={selectedData?.larynx?.subglottic === true}
                onChange={(e) =>
                  handleData('larynx', 'subglottic', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`transglottic`}
                label={`Transglottic`}
                checked={selectedData?.larynx?.transglottic === true}
                onChange={(e) =>
                  handleData('larynx', 'transglottic', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Hypopharynx:</Form.Label>
        </Col>

        {/* Radio Buttons Row */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`pyriform_fossa`}
                label={`Pyriform fossa`}
                checked={selectedData?.hypopharynx?.pyriform_fossa === true}
                onChange={(e) =>
                  handleData('hypopharynx', 'pyriform_fossa', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`post_cricoid`}
                label={`Post cricoid`}
                checked={selectedData?.hypopharynx?.post_cricoid === true}
                onChange={(e) =>
                  handleData('hypopharynx', 'post_cricoid', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.hypopharynx?.note}
                onChange={(e) =>
                  handleData('hypopharynx', 'note', e.target.value)
                }
              />
            </Col>
          </Row>

          {/* Third Radio Button on Second Row */}
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`posterior_wall`}
                label={`Posterior wall`}
                checked={selectedData?.hypopharynx?.posterior_wall === true}
                onChange={(e) =>
                  handleData('hypopharynx', 'posterior_wall', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Tongue:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`lateral_border`}
                label={`Lateral border`}
                checked={selectedData?.tongue?.lateral_border === true}
                onChange={(e) =>
                  handleData('tongue', 'lateral_border', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`dorsum`}
                label={`Dorsum`}
                checked={selectedData?.tongue?.dorsum === true}
                onChange={(e) =>
                  handleData('tongue', 'dorsum', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.tongue?.note}
                onChange={(e) => handleData('tongue', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`tip`}
                label={`Tip`}
                checked={selectedData?.tongue?.tip === true}
                onChange={(e) => handleData('tongue', 'tip', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`ventral_surface`}
                label={`Ventral surface`}
                checked={selectedData?.tongue?.ventral_surface === true}
                onChange={(e) =>
                  handleData('tongue', 'ventral_surface', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Oral Cavity:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="lip_floor_of_mouth"
                label="Lip floor of mouth"
                checked={selectedData?.oral_cavity?.lip_floor_of_mouth === true}
                onChange={(e) =>
                  handleData(
                    'oral_cavity',
                    'lip_floor_of_mouth',
                    e.target.checked,
                  )
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="buccal"
                label="Buccal"
                checked={selectedData?.oral_cavity?.buccal === true}
                onChange={(e) =>
                  handleData('oral_cavity', 'buccal', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.oral_cavity?.note}
                onChange={(e) =>
                  handleData('oral_cavity', 'note', e.target.value)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="retromollar_trigon"
                label="Retromollar trigon"
                checked={selectedData?.oral_cavity?.retromollar_trigon === true}
                onChange={(e) =>
                  handleData(
                    'oral_cavity',
                    'retromollar_trigon',
                    e.target.checked,
                  )
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="maxillary_alveolus_and_hard_palate"
                label="Maxillary alveolus and hard palate"
                checked={
                  selectedData?.oral_cavity
                    ?.maxillary_alveolus_and_hard_palate === true
                }
                onChange={(e) =>
                  handleData(
                    'oral_cavity',
                    'maxillary_alveolus_and_hard_palate',
                    e.target.checked,
                  )
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="mandibular_alveolus"
                label="Mandibular alveolus"
                checked={
                  selectedData?.oral_cavity?.mandibular_alveolus === true
                }
                onChange={(e) =>
                  handleData(
                    'oral_cavity',
                    'mandibular_alveolus',
                    e.target.checked,
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Oropharynx:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="base_of_tongue"
                label="Base of tongue"
                checked={selectedData?.oropharynx?.base_of_tongue === true}
                onChange={(e) =>
                  handleData('oropharynx', 'base_of_tongue', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="lateral_wall"
                label="Lateral wall"
                checked={selectedData?.oropharynx?.lateral_wall === true}
                onChange={(e) =>
                  handleData('oropharynx', 'lateral_wall', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.oropharynx?.note}
                onChange={(e) =>
                  handleData('oropharynx', 'note', e.target.value)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="soft_palate"
                label="Soft palate"
                checked={selectedData?.oropharynx?.soft_palate === true}
                onChange={(e) =>
                  handleData('oropharynx', 'soft_palate', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="posterior_wall"
                label="Posterior wall"
                checked={selectedData?.oropharynx?.posterior_wall === true}
                onChange={(e) =>
                  handleData('oropharynx', 'posterior_wall', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Nasopharynx:</Form.Label>
        </Col>

        {/* Note Field */}
        <Col md={8}>
          <Form.Control
            type="textarea"
            size="sm"
            placeholder="Add Note"
            defaultValue={selectedData?.nasopharynx}
            onChange={(e) => handleData('nasopharynx', '', e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Sinonasal:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="nasal_cavity"
                label="Nasal cavity"
                checked={selectedData?.sinonasal?.nasal_cavity === true}
                onChange={(e) =>
                  handleData('sinonasal', 'nasal_cavity', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="maxillary_sinus"
                label="Maxillary sinus"
                checked={selectedData?.sinonasal?.maxillary_sinus === true}
                onChange={(e) =>
                  handleData('sinonasal', 'maxillary_sinus', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.sinonasal?.note}
                onChange={(e) =>
                  handleData('sinonasal', 'note', e.target.value)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="ethmoid_sinus"
                label="Ethmoid sinus"
                checked={selectedData?.sinonasal?.ethmoid_sinus === true}
                onChange={(e) =>
                  handleData('sinonasal', 'ethmoid_sinus', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="frontal_sinus"
                label="Frontal sinus"
                checked={selectedData?.sinonasal?.frontal_sinus === true}
                onChange={(e) =>
                  handleData('sinonasal', 'frontal_sinus', e.target.checked)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="sphenoid_sinus"
                label="Sphenoid sinus"
                checked={selectedData?.sinonasal?.sphenoid_sinus === true}
                onChange={(e) =>
                  handleData('sinonasal', 'sphenoid_sinus', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="all"
                label="All"
                checked={selectedData?.sinonasal?.all === true}
                onChange={(e) =>
                  handleData('sinonasal', 'all', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Thyroid:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`left`}
                label={`Left Lobe`}
                checked={selectedData?.thyroid?.left === true}
                onChange={(e) =>
                  handleData('thyroid', 'left', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`right`}
                label={`Right Lobe`}
                checked={selectedData?.thyroid?.right === true}
                onChange={(e) =>
                  handleData('thyroid', 'right', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.thyroid?.note}
                onChange={(e) => handleData('thyroid', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`both`}
                label={`Both Lobes`}
                checked={selectedData?.thyroid?.both === true}
                onChange={(e) =>
                  handleData('thyroid', 'both', e.target.checked)
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Salivary Gland:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="parotid_rt"
                label="Parotid (Rt)"
                checked={selectedData?.salivary_gland?.parotid_rt === true}
                onChange={(e) =>
                  handleData('salivary_gland', 'parotid_rt', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="parotid_lt"
                label="Parotid (Lt)"
                checked={selectedData?.salivary_gland?.parotid_lt === true}
                onChange={(e) =>
                  handleData('salivary_gland', 'parotid_lt', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.salivary_gland?.note}
                onChange={(e) =>
                  handleData('salivary_gland', 'note', e.target.value)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="submandibular_rt"
                label="Submandibular (Rt)"
                checked={
                  selectedData?.salivary_gland?.submandibular_rt === true
                }
                onChange={(e) =>
                  handleData(
                    'salivary_gland',
                    'submandibular_rt',
                    e.target.checked,
                  )
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="submandibular_lt"
                label="Submandibular (Lt)"
                checked={
                  selectedData?.salivary_gland?.submandibular_lt === true
                }
                onChange={(e) =>
                  handleData(
                    'salivary_gland',
                    'submandibular_lt',
                    e.target.checked,
                  )
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="sublingual"
                label="Sublingual"
                checked={selectedData?.salivary_gland?.sublingual === true}
                onChange={(e) =>
                  handleData('salivary_gland', 'sublingual', e.target.checked)
                }
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="minor_salivary_gland"
                label="Minor Salivary Gland"
                checked={
                  selectedData?.salivary_gland?.minor_salivary_gland === true
                }
                onChange={(e) =>
                  handleData(
                    'salivary_gland',
                    'minor_salivary_gland',
                    e.target.checked,
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Skin:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`scalp`}
                label={`Scalp`}
                checked={selectedData?.skin?.scalp === true}
                onChange={(e) => handleData('skin', 'scalp', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`face`}
                label={`Face`}
                checked={selectedData?.skin?.face === true}
                onChange={(e) => handleData('skin', 'face', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.skin?.note}
                onChange={(e) => handleData('skin', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type={`radio`}
                id={`neck`}
                label={`Neck`}
                checked={selectedData?.skin?.neck === true}
                onChange={(e) => handleData('skin', 'neck', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-3 mb-3">
        <Col md={2}>
          <Form.Label>Lymphoma</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            type="textarea"
            size="sm"
            placeholder="Add Note"
            defaultValue={selectedData?.lymphoma}
            onChange={(e) => handleData('lymphoma', '', e.target.value)}
          />
        </Col>
      </Form.Group>

      <div className="title-gyne-examination">
        Lymph Node
      </div>
      <hr className="hr" />

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Site:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="site_rt"
                label="RT"
                checked={selectedData?.site?.rt === true}
                onChange={(e) => handleData('site', 'rt', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="site_lt"
                label="LT"
                checked={selectedData?.site?.lt === true}
                onChange={(e) => handleData('site', 'lt', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.site?.note}
                onChange={(e) => handleData('site', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="site_both"
                label="Both"
                checked={selectedData?.site?.both === true}
                onChange={(e) => handleData('site', 'both', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="site_central"
                label="Central"
                checked={selectedData?.site?.central === true}
                onChange={(e) => handleData('site', 'central', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Level:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_ia"
                label="I(A)"
                checked={selectedData?.level?.ia === true}
                onChange={(e) => handleData('level', 'ia', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_ib"
                label="I(B)"
                checked={selectedData?.level?.ib === true}
                onChange={(e) => handleData('level', 'ib', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.level?.note}
                onChange={(e) => handleData('level', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_ii"
                label="II"
                checked={selectedData?.level?.ii === true}
                onChange={(e) => handleData('level', 'ii', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_iii"
                label="III"
                checked={selectedData?.level?.iii === true}
                onChange={(e) => handleData('level', 'iii', e.target.checked)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_iv"
                label="IV"
                checked={selectedData?.level?.iv === true}
                onChange={(e) => handleData('level', 'iv', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_va"
                label="V(A)"
                checked={selectedData?.level?.va === true}
                onChange={(e) => handleData('level', 'va', e.target.checked)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="level_vb"
                label="V(B)"
                checked={selectedData?.level?.vb === true}
                onChange={(e) => handleData('level', 'vb', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Size:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="size_3_4"
                label="3/4"
                checked={selectedData?.size?.three_four === true}
                onChange={(e) => handleData('size', 'three_four', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="size_4_3"
                label="4/3"
                checked={selectedData?.size?.four_three === true}
                onChange={(e) => handleData('size', 'four_three', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.size?.note}
                onChange={(e) => handleData('size', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="size_5_2"
                label="5/2"
                checked={selectedData?.size?.five_two === true}
                onChange={(e) => handleData('size', 'five_two', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Consistency:</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="consistency_firm"
                label="Firm"
                checked={selectedData?.consistency?.firm === true}
                onChange={(e) => handleData('consistency', 'firm', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="consistency_hard"
                label="Hard"
                checked={selectedData?.consistency?.hard === true}
                onChange={(e) => handleData('consistency', 'hard', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.consistency?.note}
                onChange={(e) => handleData('consistency', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="consistency_soft"
                label="Soft"
                checked={selectedData?.consistency?.soft === true}
                onChange={(e) => handleData('consistency', 'soft', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="pb-2 mt-3">
        {/* Label */}
        <Col md={2}>
          <Form.Label>Relation with Underlying Structure</Form.Label>
        </Col>

        {/* Radio Buttons */}
        <Col md={10}>
          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="structure_free"
                label="Free"
                checked={selectedData?.structure?.free === true}
                onChange={(e) => handleData('structure', 'free', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="structure_fixed"
                label="Fixed"
                checked={selectedData?.structure?.fixed === true}
                onChange={(e) => handleData('structure', 'fixed', e.target.checked)}
              />
            </Col>
            <Col md={4} className="text-end">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add note"
                style={{ height: '34px', width: '100%' }}
                defaultValue={selectedData?.structure?.note}
                onChange={(e) => handleData('structure', 'note', e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="structure_mobile"
                label="Mobile"
                checked={selectedData?.structure?.mobile === true}
                onChange={(e) => handleData('structure', 'mobile', e.target.checked)}
              />
            </Col>
            <Col md={4} className="d-flex justify-content-start">
              <Form.Check
                type="radio"
                id="structure_immobile"
                label="Immobile"
                checked={selectedData?.structure?.immobile === true}
                onChange={(e) => handleData('structure', 'immobile', e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>


    </div>
  );
};

export default memo(EarNoseThrothExaminationNew);
