import React, { memo, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { checkDoctorDept, handleGroupData } from '../../../utils/helpers';
import DiagnosisTabNav from '../partials/diagnosisTabNav';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import SearchAreaAsync from '../partials/searchAreaAsync';
import Staging from '../partials/staging';
import SearchItemSuggestions from '../searchItemSuggestions';
import DiagnosisGroupForm from './diagnosisGroupForm';

const DiagnosisModal = ({
  modalTitle,
  selectedDiagnosis,
  setSelectedDiagnosis,
  selectedDiagnosisTab,
  setSelectedDiagnosisTab,
  selectedDiagnosisGroups,
  setSelectedDiagnosisGroups,
  showDiagnosis,
  setShowDiagnosis,
}) => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [diagnosesInSearch, setDiagnosesInSearch] = useState([]);
  const [diagnosisGroups, setDiagnosisGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const perPage = 25;
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const [suggestions, setSuggestions] = useState([]);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const getDiagnosisGroups = async () => {
    await getRequest('diagnoses/groups')
      .then((data) => {
        setDiagnosisGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getDiagnosisSuggestions',
      `prescriptions/suggestions?suggestion_type=diagnosis`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isDiagnosisLoading, refetch: getDiagnosisList } =
    useGetRequest(
      'getDiagnosis',
      `diagnoses?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setDiagnoses([...diagnoses, ...data.data]);
        } else {
          setDiagnoses(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectDiagnosis = (item) => {
    if (selectedDiagnosis.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.filter((diagnosis) => diagnosis.name !== item.name),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, side: '', note: '' },
      ]);
    }
  };

  const deleteDiagnosis = async (diagnosisId) => {
    await deleteRequest(`diagnoses/${diagnosisId}`)
      .then((data) => {
        setDiagnoses(
          diagnoses.filter((diagnosis) => diagnosis.id !== diagnosisId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectDiagnosisWithNote = (item) => {
    if (selectedDiagnosis.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.map((diagnosis) => {
          if (diagnosis.name !== item.name) return diagnosis;
          else return { ...diagnosis, note: item?.note };
        }),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, side: '', note: item?.note },
      ]);
    }
  };

  const deleteDiagnosisGroup = async (groupId) => {
    await deleteRequest(`diagnoses/groups/${groupId}`)
      .then((data) => {
        setDiagnosisGroups(
          diagnosisGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectDiagnosisGroup = (group) => {
    handleGroupData(
      group,
      selectedDiagnosisGroups,
      setSelectedDiagnosisGroups,
      selectedDiagnosis,
      setSelectedDiagnosis,
      'Diagnosis group',
    );
  };

  const removeDiagnosis = (item) => {
    setSelectedDiagnosis(
      selectedDiagnosis.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleDiagnosesExtra = (index, fieldName, value) => {
    selectedDiagnosis[index][fieldName] = value;
    setSelectedDiagnosis([...selectedDiagnosis]);
  };

  let allDiagnose = diagnoses.map((item, index) => {
    let isSelected = selectedDiagnosis.find((data) => data.name === item.name);
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectDiagnosis}
        removeClickAction={deleteDiagnosis}
        suggestions={suggestions}
        selectItemWithNote={selectDiagnosisWithNote}
      />
    );
  });

  let groupWithMinusBtn = diagnosisGroups.map((group, index) => {
    let isSelected = selectedDiagnosisGroups.find(
      (data) => data.id === group.id,
    );
    isSelected = isSelected ? true : false;

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectDiagnosisGroup}
        removeClickAction={deleteDiagnosisGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('diagnoses', { name: selectedData });
        setDiagnoses([...diagnoses, data]);
        setSearchQuery('');
        setSelectedDiagnosis([
          ...selectedDiagnosis,
          { name: selectedData, query: false, side: '', note: '' },
        ]);
      } else {
        const isExisting = selectedDiagnosis?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );

        setSelectedDiagnosis(
          isExisting
            ? selectedDiagnosis?.filter(
                ({ name }) =>
                  name?.toLowerCase() !== selectedData?.toLowerCase(),
              )
            : [
                ...selectedDiagnosis,
                { name: selectedData, query: false, side: '', note: '' },
              ],
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = async (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setDiagnosesInSearch([]);
      const url = `diagnoses?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      await getRequest(url)
        .then((data) => {
          setDiagnosesInSearch(data?.data);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const selectedList = () => {
    return selectedDiagnosis?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col lg={3} md={3} sm={3} xs={5}>
            {item.name}
          </Col>
          <Col lg={2} md={2} sm={2} xs={4} className="pr-0 responsive">
            <Form.Check
              type={`checkbox`}
              id={`query-${index}`}
              label={`Query`}
              checked={item.query}
              onChange={(evt) =>
                handleDiagnosesExtra(index, 'query', evt.target.checked)
              }
            />
          </Col>
          <Col lg={2} md={2} sm={2} xs={3} className="pl-0 pr-0">
            <Form.Select
              className="form-control form-control-sm"
              defaultValue={item.side}
              onChange={(e) =>
                handleDiagnosesExtra(index, 'side', e.target.value)
              }
            >
              <option value="">Side</option>
              <option value={'rt'}>RT</option>
              <option value={'lt'}>LT</option>
              <option value={'bil'}>BIL</option>
            </Form.Select>
          </Col>
          <Col lg={4} md={4} sm={3} xs={8} className="pr-0 responsive">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) =>
                handleDiagnosesExtra(index, 'note', e.target.value)
              }
            />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle cursor-pointer pt-1"
              aria-hidden="true"
              onClick={() => removeDiagnosis(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  const isOncologyOrHematolgy = (specialityName) => {
    const result = checkDoctorDept(
      specialityName,
      userInfo?.speciality[0],
      specialties,
    );
    return result ? 'staging' : '';
  };

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = diagnoses?.find(
      (diagnosisItem) => diagnosisItem.name === itemName,
    );
    if (selectedDiagnosis?.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.map((diagnosis) => {
          if (diagnosis.name !== item.name) return diagnosis;
          else return { ...diagnosis, note: item?.note };
        }),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, note: note },
      ]);
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    console.log('x, y : ', e.pageX, e.pageY);

    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };

  //============End search suggestions ======================//

  useEffect(() => {
    getDiagnosisGroups();
  }, []);

  useEffect(() => {
    getDiagnosisList();
  }, [currentPage]);

  useEffect(() => {
    if (showDiagnosis) {
      getSuggestions();
    } else {
      setSearchQuery('');
      setDiagnosesInSearch([]);
    }
  }, [showDiagnosis]);

  return (
    <Modal
      show={showDiagnosis}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowDiagnosis} /> */}
      <Modal.Body>
        <SearchAreaAsync
          modalTitle={modalTitle}
          closeAction={setShowDiagnosis}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={diagnosesInSearch}
          setSearchOptions={setDiagnosesInSearch}
          selectedInputs={selectedDiagnosis}
          placeholder={'diagnosis'}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <DiagnosisTabNav
            action={setIsGroupModal}
            selectedItems={selectedDiagnosis}
            setSelectedDiagnosisTab={setSelectedDiagnosisTab}
            type={
              isOncologyOrHematolgy('oncology') ||
              isOncologyOrHematolgy('hematology')
            }
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">{allDiagnose}</Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
            {selectedDiagnosisTab === 'staging' && (
              <Tab.Pane
                eventKey="staging"
                style={{ height: '390px' }}
                className="add-scroll"
              >
                <Staging />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>

        <DiagnosisGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedDiagnosis.map((diagnosis) => diagnosis.name)}
          diagnosisGroups={diagnosisGroups}
          setDiagnosisGroups={setDiagnosisGroups}
          selectedDiagnosisGroups={selectedDiagnosisGroups}
          setSelectedDiagnosisGroups={setSelectedDiagnosisGroups}
        />
        <hr className="selected-hr" />
        {selectedDiagnosisTab !== 'staging' && (
          <>
            <div className="selected-item-title">Selected list</div>
            <div className="selected-item-area">{selectedList()}</div>
          </>
        )}

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(DiagnosisModal);
