import React, { memo, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetRequest } from '../../../hooks/useGetRequest';
import { getRequest, postRequest } from '../../../utils/axiosRequests';
import BreastExamination from '../partials/breastExamination';
import DentalExamination from '../partials/dentalExamination';
import EarNoseThrothExaminationNew from '../partials/earNoseThrothExaminationNew';
import GeneralExamination from '../partials/generalExamination';
import GyneExamination from '../partials/gyneExamination';
import NeurologyExamination from '../partials/neurologyExamination';
import Observation from '../partials/observation';
import OcularExamination from '../partials/ocularExamination';
import OnExaminationTabNav from '../partials/onExaminationTabNav';
import OncologyExamination from '../partials/oncologyExamination';
import SearchArea from '../partials/searchAreaAsync';
import SystemicExamination from '../partials/systemicExamination';
import SearchItemSuggestions from '../searchItemSuggestions';

const OnExamination = ({
  modalTitle,
  patientInfo,
  setPatientInfo,
  showOnExamination,
  setShowOnExamination,
  handleOnExaminations,
  selectedOnExamination,
  setSelectedOnExamination,
  selectedOnExaminationTab,
  setSelectedOnExaminationTab,
}) => {
  let {
    observations,
    gyneExamination,
    breastExamination,
    earNoseThroths,
    dentalExamination,
  } = selectedOnExamination;

  observations = observations?.length ? observations : [];
  const [allObservations, setAllObservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [observationsInSearch, setObservationsInSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const perPage = 25;
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const [suggestions, setSuggestions] = useState([]);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const { isLoading: isObservationLoading, refetch: getObservations } =
    useGetRequest(
      'getObservation',
      `observations?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setAllObservations([...allObservations, ...data.data]);
        } else {
          setAllObservations(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getObservationSuggestions',
      `prescriptions/suggestions?suggestion_type=observation`,
      (data) => {
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const handleOnExaminationData = (updatedData, type) => {
    switch (type) {
      case 'observation':
        selectedOnExamination.observations = updatedData;
        break;
      case 'gyneExamination':
        selectedOnExamination.gyneExamination = updatedData;
        break;
      case 'breastExamination':
        selectedOnExamination.breastExamination = updatedData;
        break;
      case 'ocularExamination':
        selectedOnExamination.ocularExamination = updatedData;
        break;
      case 'generalExamination':
        selectedOnExamination.generalExaminations = updatedData;
        break;
      case 'dental':
        selectedOnExamination.dentalExamination = updatedData;
        break;
      case 'systemicExamination':
        selectedOnExamination[type] = updatedData;
        break;
      case 'oncologyExamination':
        selectedOnExamination[type] = updatedData;
        break;
      case 'earNoseThroths':
        selectedOnExamination[type] = updatedData;
        break;
      case 'nervousSystem':
        selectedOnExamination[type] = updatedData;
        break;
      default:
    }
    handleOnExaminations({ ...selectedOnExamination });
  };

  const handleOnInputChange = async (searchKey) => {
    setIsLoading(true);
    const url = `observations?name=${encodeURIComponent(searchKey)}`;
    setSearchQuery(searchKey);
    await getRequest(url)
      .then((data) => {
        setObservationsInSearch(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('observations', {
          name: selectedData,
        });
        setSearchQuery('');
        setAllObservations([...allObservations, data]);
        const result = [
          ...observations,
          { name: selectedData, side: '', note: '' },
        ];
        handleOnExaminations({
          ...selectedOnExamination,
          observations: result,
        });
      } else {
        const isExisting = observations?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );
        const result = isExisting
          ? observations?.filter(
              ({ name }) => name?.toLowerCase() !== selectedData?.toLowerCase(),
            )
          : [...observations, { name: selectedData, side: '', note: '' }];
        handleOnExaminations({
          ...selectedOnExamination,
          observations: result,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeObservation = (itemName) => {
    observations = observations?.filter(
      (selectedItem) => selectedItem.name !== itemName,
    );
    handleOnExaminationData(observations, 'observation');
  };

  useEffect(() => {
    getObservations();
  }, [currentPage]);

  useEffect(() => {
    if (showOnExamination) {
      getSuggestions();
    } else {
      setSearchQuery('');
      setObservationsInSearch([]);
    }
  }, [showOnExamination]);

  const handleNoteOfObservation = (index, fieldName, val) => {
    observations[index][fieldName] = val;
    handleOnExaminationData(observations, 'observation');
  };

  const selectedObservation = () => {
    return observations?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col>{item.name}</Col>
          <Col md={2}>
            <Form.Select
              className="form-control form-control-sm"
              defaultValue={item?.side}
              onChange={(e) =>
                handleNoteOfObservation(index, 'side', e.target.value)
              }
            >
              <option value="">Side</option>
              <option value={'rt'}>RT</option>
              <option value={'lt'}>LT</option>
              <option value={'bil'}>BIL</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter note"
              defaultValue={item.note}
              onChange={(e) =>
                handleNoteOfObservation(index, 'note', e.target.value)
              }
            />
          </Col>
          <Col md="1" className="text-right">
            <i
              className="fa fa-times-circle pt-1"
              aria-hidden="true"
              onClick={() => removeObservation(item.name)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = allObservations.find(
      (observationItem) => observationItem.name === itemName,
    );
    item['note'] = note;
    if (observations?.some((observation) => observation.name === item.name)) {
      observations = observations.map((observation) =>
        observation.name !== item.name
          ? observation
          : { ...observation, note: item?.note },
      );
      handleOnExaminationData(observations, 'observation');
    } else {
      observations = [...observations, { name: item.name, note: item?.note }];
      handleOnExaminationData(observations, 'observation');
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  return (
    <Modal
      show={showOnExamination}
      size="lg"
      className="customize-modal-size onexamination-modal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowOnExamination} /> */}
      <Modal.Body>
        {selectedOnExaminationTab === 'observation' && (
          <SearchArea
            modalTitle={modalTitle}
            closeAction={setShowOnExamination}
            setSearchOptions={setObservationsInSearch}
            handleOnInputChange={handleOnInputChange}
            handleSearchOrNew={handleSearchOrNew}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={observationsInSearch}
            selectedInputs={observations}
            placeholder={'observation'}
            handleClickOnRightArrow={handleClickOnRightArrow}
          />
        )}
        <Tab.Container id="left-tabs-example" defaultActiveKey="observation">
          <OnExaminationTabNav
            tabKey={selectedOnExaminationTab}
            setSelectedOnExaminationTab={setSelectedOnExaminationTab}
            specialties={specialties}
          />
          <Tab.Content>
            <Tab.Pane eventKey="observation" className="add-scroll">
              <Observation
                allObservations={allObservations}
                observations={observations}
                handleOnExaminationData={handleOnExaminationData}
                handleNoteOfObservation={handleNoteOfObservation}
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                setCurrentPage={setCurrentPage}
                suggestions={suggestions}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="generalExamination" style={{ height: '450px' }}>
              <GeneralExamination
                selectedOnExamination={selectedOnExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="ocularExamination"
              className="add-scroll ocular-examination pt-2"
              style={{ height: '455px' }}
            >
              <OcularExamination
                selectedOnExamination={selectedOnExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="gyneExamination"
              className="add-scroll pt-2"
              style={{ height: '452px' }}
            >
              <GyneExamination
                gyneExamination={gyneExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="breastExamination"
              className="add-scroll"
              style={{ height: '453px' }}
            >
              <BreastExamination
                breastExamination={breastExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="earNoseThroatExamination"
              className="add-scroll"
              style={{ height: '453px' }}
            >
              <EarNoseThrothExaminationNew
                earNoseThroths={earNoseThroths}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="dentalExamination">
              <DentalExamination
                dentalExamination={dentalExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="systemicExamination"
              className="systemic-examination"
            >
              <SystemicExamination
                selectedOnExamination={selectedOnExamination}
                setSelectedOnExamination={setSelectedOnExamination}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="oncologyExamination"
              className="oncology-examination"
            >
              <OncologyExamination
                patientInfo={patientInfo}
                setPatientInfo={setPatientInfo}
                selectedOnExamination={selectedOnExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="neurologyExamination"
              className="oncology-examination"
            >
              <NeurologyExamination
                patientInfo={patientInfo}
                setPatientInfo={setPatientInfo}
                selectedOnExamination={selectedOnExamination}
                handleOnExaminationData={handleOnExaminationData}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {selectedOnExaminationTab === 'observation' && (
          <>
            <hr className="selected-hr" />
            <div className="selected-item-title">Selected list</div>
            <div className="selected-item-area">{selectedObservation()}</div>
          </>
        )}

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(OnExamination);
