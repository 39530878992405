import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TopLeftMenu = () => {
  const userInfo = useSelector((state) => state.authReducer.data);

  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );
  console.log('activeDoctor: ', activeDoctor, userInfo);
  const url = window.location.pathname.substr(1);
  const MenuLabel = ({ menuTitle, item }) => {
    return <span className="font-weight-bold">{menuTitle}</span>;
  };

  return (
    <div className="left-side-pres">
      <div style={{ width: '73px' }}></div>
      {userInfo.userType !== 'assistant' && (
        <Link
          className={`nav-link ${url === 'prescription' ? 'active' : ''}`}
          to="/prescription"
          title="Rx"
        >
          <MenuLabel menuTitle="Rx" item={'prescription'} />
        </Link>
      )}
      <Link
        className={`nav-link ${url === 'appointments' ? 'active' : ''}`}
        to="/appointments"
        title="Appointments"
      >
        <i className="far fa-calendar-alt fa-1x stat-icon"></i>
      </Link>
      {userInfo.userType === 'assistant' &&
        activeDoctor.doctorDetails?.smsModule && (
          <Link
            className={`nav-link ${url === 'send-sms' ? 'active' : ''}`}
            to="/send-sms"
            title="SMS"
          >
            <i className="fas fa-fw fa-envelope"></i>
          </Link>
        )}
      {userInfo.userType !== 'assistant' && (
        <>
          <Link
            className={`nav-link ${url === 'patients' ? 'active' : ''}`}
            to="/patients"
            title="Patients"
          >
            <i className="fa fa-users"></i>
          </Link>
          <Link
            className={`nav-link ${url === 'home' ? 'active' : ''}`}
            to="/home"
            title="Reports"
          >
            <i className="fas fa-chart-bar"></i>
          </Link>
          {userInfo.doctorDetails?.smsModule && (
            <Link
              className={`nav-link ${url === 'send-sms' ? 'active' : ''}`}
              to="/send-sms"
              title="SMS"
            >
              <i className="fas fa-fw fa-envelope"></i>
            </Link>
          )}
          <Link
            className={`nav-link ${url === 'settings' ? 'active' : ''}`}
            to="/settings"
            title="Settings"
          >
            <i className="fas fa-fw fa-cog"></i>
          </Link>
        </>
      )}
    </div>
  );
};

export default memo(TopLeftMenu);
